<template>
    <div class="service-packs-update-modal">
        <ModalContainer :title="$t('service_packs.edit_service_pack')" identifier="service-packs-update-modal"
                        :closable="true">
            <Form class="form" @submit="save" :disabled="is_saving">
                <FormInputText v-model="$v.servicePack.name.$model" identifier="name" :label="$t('service_packs.name')"
                               :placeholder="$t('service_packs.name')" :disabled="is_saving"
                               :has-error="$v.servicePack.name.$error">
                    <template v-slot:errors>
                        <p v-if="!$v.servicePack.name.required">
                            {{ $t('validation.x_is_required', {x: $t('service_packs.name')}) }}
                        </p>
                    </template>
                </FormInputText>
                <FormInputSelect v-model="$v.servicePack.services.$model" identifier="services"
                                 :label="$t('service_packs.services')" :options="serviceOptions"
                                 :placeholder="$t('service_packs.services')" :disabled="is_saving"
                                 :has-error="$v.servicePack.services.$error" track-by="id" :allow-empty="false"
                                 display-label="name" :multiple="true" class="select">
                    <template v-slot:errors>
                        <p v-if="!$v.servicePack.services.required">
                            {{ $t('validation.x_are_required', {x: $t('service_packs.services')}) }}
                        </p>
                    </template>
                </FormInputSelect>
                <FormInputText v-model="$v.servicePack.description.$model" identifier="description"
                               :label="$t('service_packs.description')"
                               :placeholder="$t('service_packs.description')" :disabled="is_saving"
                               :has-error="$v.servicePack.description.$error">
                    <template v-slot:errors>
                        <p v-if="!$v.servicePack.description.required">
                            {{ $t('validation.x_is_required', {x: $t('service_packs.description')}) }}
                        </p>
                    </template>
                </FormInputText>
                <FormGroupTwo>
                    <FormInputText v-model="$v.servicePack.price.$model" identifier="price"
                                   :label="$t('service_packs.price')"
                                   :placeholder="$t('service_packs.price')" :disabled="is_saving"
                                   :has-error="$v.servicePack.price.$error">
                        <template v-slot:errors>
                            <p v-if="!$v.servicePack.price.required">
                                {{ $t('validation.x_is_required', {x: $t('service_packs.price')}) }}
                            </p>
                            <p v-if="!$v.servicePack.price.numeric">
                                {{ $t('validation.please_enter_number') }}
                            </p>
                        </template>
                    </FormInputText>
                    <div class="check-container">
                        <FormInputCheck v-model="$v.servicePack.is_featured.$model" identifier="is_featured"
                                        :label="$t('service_packs.highlight_service_pack')"
                                        :disabled="is_saving" :has-error="$v.servicePack.is_featured.$error"/>
                    </div>
                </FormGroupTwo>
                <Button type="submit" className="--secondary --small --wide" :class="{spinner: is_saving}">
                    {{ $t('save') }}
                </Button>
            </Form>
        </ModalContainer>
    </div>
</template>

<script>
import ModalContainer from "../modal/ModalContainer";
import Form from "../form/Form";
import FormInputText from "../form/FormInputText";
import FormGroupTwo from "../form/FormGroupTwo";
import FormInputSelect from "../form/FormInputSelect";
import {required, numeric} from 'vuelidate/lib/validators'
import Button from "../Button";
import FormInputPassword from "@/components/form/FormInputPassword";
import FormInputCheck from "../form/FormInputCheck";
import _ from "lodash";

export default {
    name: "ServicePacksUpdateModal",
    components: {
        FormInputCheck,
        FormInputPassword, Button, FormInputSelect, FormGroupTwo, FormInputText, Form, ModalContainer
    },
    data() {
        return {
            servicePack: {
                name: null,
                services: null,
                description: null,
                price: null,
                is_featured: false,
            },
            serviceOptions: [],
            is_saving: false,
            is_loading_services: false,
            original: null,
        }
    },
    props: {
        servicePack_id: {
            type: Number,
            required: true
        }
    },
    validations: {
        servicePack: {
            name: {required},
            services: {required},
            description: {required},
            price: {required, numeric},
            is_featured: {required},
        }
    },
    methods: {
        close(status) {
            this.$modal.hide('service-packs-update-modal', status);
        },
        save() {
            console.log("attempt")
            console.log(this.$v.servicePack)
            this.$v.servicePack.$touch();
            if (this.$v.servicePack.$anyError || this.is_saving)
                return;

            console.log("Passed")

            this.is_saving = true;
            const payload = Object.assign({}, this.$v.servicePack.$model);

            this.$axios.patch(`servicePacks/${this.servicePack_id}`, payload).then(({data}) => {
                this.$notify({
                    text: this.$t('users.success_updated'),
                    type: 'success',
                });

                this.is_saving = false;
                this.close(true);
            }).catch(e => {
                this.is_saving = false;

                this.$notify({
                    title: this.$t('error'),
                    text: this.$larerror(e.response.data.errors, this.$t('users.error_update')),
                    type: 'error',
                });
            });
        },
        populate() {
            if (!this.original || !this.servicePack)
                return;

            this.$v.servicePack.name.$model = this.original.attributes.name;
            this.$v.servicePack.description.$model = this.original.attributes.description;
            this.$v.servicePack.price.$model = this.original.attributes.price;
            this.$v.servicePack.is_featured.$model = this.original.attributes.is_featured;
            this.$v.servicePack.services.$model = this.original.services;
        },
        retrieveOriginalServicePack() {
            this.is_loading_original = true;
            this.$axios.get(`servicePacks/${this.servicePack_id}`)
                .then(({data}) => {
                    this.original = data.data;

                    if (data.data.relationships.services.length)
                        this.original.services = this.serviceOptions.filter(po =>  data.data.relationships.services.find(p => p.id === po.id));

                    this.is_loading_original = false;
                    this.populate();
                })
                .catch(e => {
                    this.is_loading_original = false;
                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('users.error_retrieve')),
                        type: 'error',
                    });
                });
        },
        async retrieveServices() {
            this.is_loading_services = true;
            await this.$axios.get('services')
                .then(({data}) => {
                    this.serviceOptions = data.data.map(p => ({id: p.id, name: p.attributes.name}));
                    this.is_loading_services = false;
                })
                .catch(e => {
                    this.is_loading_services = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('services.error_retrieve')),
                        type: 'error',
                    });
                });
        },
    },
    async mounted() {
        await this.retrieveServices();
        this.retrieveOriginalServicePack();
    }
}
</script>

<style lang="scss" scoped>
.check-container{
    @apply flex flex-row h-8 mx-auto w-full justify-between items-center mb-5;

    @screen md{
        @apply flex flex-row h-24 w-1/2 justify-around items-center mb-0;

    }

    .p-icon{
        @apply mt-6 mx-3;
        transform: scale(1.25);
        margin-right:1rem !important;

        @screen md{
            @apply mx-0;
        }
    }
}
</style>
