<template>
    <ApplicationFormBase :title="$t('applications.form_4_text')" :form_number="4">
        <Form class="form-container">
            <div class="input-row-container">
                <div class="text-container">
                    <p>{{ $t('applications.authorized_shares_company') }}</p>
                    <font-awesome-icon v-tippy="{ placement : 'right',  arrow: true }" :content="$t('applications.authorized_shares_company_tt')"
                                       :icon="['fas','question-circle']"
                                       class="info"/>
                </div>
                <FormInputText v-model="application_data.authorizedShares" class="form-input"
                               identifier="authorized_shares_company" type="number" disabled>
                </FormInputText>
            </div>

            <div class="input-row-container">
                <div class="text-container">
                    <p>{{ $t('applications.issued_shares_company') }}</p>
                    <font-awesome-icon v-tippy="{ placement : 'right',  arrow: true }" :content="$t('applications.issued_shares_company_tt')"
                                       :icon="['fas','question-circle']"
                                       class="info"/>
                </div>
                <FormInputText v-model="application_data.issuedShares" class="form-input"
                               identifier="issued_shares_company" type="number" disabled>
                </FormInputText>
            </div>

            <div class="input-row-container">
                <div class="text-container">
                    <p>{{ $t('applications.percentage_capital_paid') }}</p>
                    <font-awesome-icon v-tippy="{ placement : 'right',  arrow: true }" :content="$t('applications.percentage_capital_paid_tt')"
                                       :icon="['fas','question-circle']"
                                       class="info"/>
                </div>
                <FormInputText v-model="application_data.percentageCapitalPaid" class="form-input"
                               identifier="percentage_capital_paid" type="number" disabled>
                </FormInputText>
            </div>

            <div class="input-row-container">
                <div class="text-container">
                    <p>{{ $t('applications.currency_shares_issued') }}</p>
                    <font-awesome-icon v-tippy="{ placement : 'right',  arrow: true }" :content="$t('applications.currency_shares_issued_tt')"
                                       :icon="['fas','question-circle']"
                                       class="info"/>
                </div>

                <FormInputSelect
                    v-model="application_data.sharesCurrency"
                    class="form-input"
                    display-label="name"
                    identifier="currency_shares_issued"
                    track-by="value"
                    disabled>
                </FormInputSelect>
            </div>

            <div class="input-row-container">
                <div class="text-container">
                    <p>{{ $t('applications.nominal_value_share') }}</p>
                    <font-awesome-icon v-tippy="{ placement : 'right',  arrow: true }" :content="$t('applications.nominal_value_share_tt')"
                                       :icon="['fas','question-circle']"
                                       class="info"/>
                </div>
                <FormInputText v-model="application_data.valueEachShare" class="form-input"
                               identifier="nominal_value_share" type="number" disabled>
                </FormInputText>
            </div>
        </Form>
    </ApplicationFormBase>
</template>

<script>
import ApplicationFormBase from "./ApplicationFormBase";
import FormInputText from "../form/FormInputText";
import FormInputSelect from "../form/FormInputSelect";

export default {
    name: "ApplicationForm4",
    components: { ApplicationFormBase, FormInputText, FormInputSelect },
    props: {
        application_data: {
            type: Object,
            required: true,
        }
    },
    data() {
        return {
        }
    },
    methods: {

    },

}
</script>

<style lang="scss" scoped>
.form-container {
    @apply w-full;

    .input-row-container {
        @apply flex flex-col items-start mb-6;

        @screen md {
            @apply flex-row items-center mb-12;
        }

        .text-container {
            @apply flex flex-row items-center mb-4;

            @screen md {
                @apply mb-0 flex-1;
            }

            & > p {
                @apply text-black text-xs leading-normal;

                @screen md {
                    @apply text-sm leading-normal;
                }
            }

            & > svg.info {
                @apply text-black text-sm ml-3;
            }
        }

        .form-input {
            @apply w-32 mb-0;
        }
    }
}

</style>