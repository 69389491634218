<template>
    <div class="page-container">
        <Headbar>
            <template v-slot:left>
                <h1 v-if="original">{{ original.attributes.name }}</h1>
            </template>
            <template v-slot:right>
                <Button v-if="$store.getters.hasPermission('update users')" className="--secondary --small --wider"
                        :class="{spinner: is_saving}" :onclick="save">
                    {{ $t('save') }}
                </Button>
            </template>
        </Headbar>
        <main>
            <div class="section-container">
                <Form class="form" :disabled="!$store.getters.hasPermission('update users') || is_saving">
                    <SectionHeader :title="$t('customers.customer_details')"></SectionHeader>
                    <div class="form-body">
                        <FormGroupTwo>
                            <FormInputText v-model="$v.customer.name.$model" identifier="name" :label="$t('users.name')"
                                           :placeholder="$t('users.name')" :disabled="is_saving"
                                           :has-error="$v.customer.name.$error">
                                <template v-slot:errors>
                                    <p v-if="!$v.user.name.required">
                                        {{ $t('validation.x_is_required', {x: $t('users.name')}) }}
                                    </p>
                                </template>
                            </FormInputText>
                            <FormInputDateTime v-model="$v.customer.dob.$model" identifier="dob"
                                               :label="$t('users.dob')"
                                               :placeholder="$t('users.dob')" :disabled="is_saving"
                                               :has-error="$v.customer.dob.$error">
                                <template v-slot:errors>
                                    <p v-if="!$v.user.dob.required">
                                        {{ $t('validation.x_is_required', {x: $t('users.dob')}) }}
                                    </p>
                                </template>
                            </FormInputDateTime>
                        </FormGroupTwo>
                        <FormGroupTwo>
                            <FormInputText v-model="$v.customer.phone.$model" identifier="name"
                                           :label="$t('users.mobile_number')"
                                           :placeholder="$t('users.mobile_number')" :disabled="is_saving"
                                           :has-error="$v.customer.phone.$error">
                                <template v-slot:errors>
                                    <p v-if="!$v.user.password.required">
                                        {{ $t('validation.x_is_required', {x: $t('users.password')}) }}
                                    </p>
                                </template>
                            </FormInputText>
                            <FormInputText v-model="$v.customer.email.$model" identifier="name"
                                           :label="$t('users.email')"
                                           :placeholder="$t('users.email')" :disabled="is_saving"
                                           :has-error="$v.customer.email.$error">
                                <template v-slot:errors>
                                    <p v-if="!$v.user.role.required">
                                        {{ $t('validation.x_is_required', {x: $t('users.role')}) }}
                                    </p>
                                </template>
                            </FormInputText>
                        </FormGroupTwo>
                    </div>
                </Form>
            </div>

            <div class="section-container">
                <h2>{{ $t('customers.applications') }}</h2>
                <vue-good-table
                    styleClass="vgt-table vgt-custom vgt-responsive-fix"
                    :columns="applicationsColumns"
                    :rows="applications"
                    :isLoading.sync="is_loading_applications"
                    :search-options="{
                    enabled: false,
                }"
                    :pagination-options="{
                    enabled: true,
                    mode: 'records',
                    dropdownAllowAll: false,
                    perPage: 15,
                    perPageDropdownEnabled: false,
                    rowsPerPageLabel: $t('x_per_page', {x: $t('customers.customers')}),
                }"
                    :sort-options="{
                  enabled: true,
                  multipleColumns: true,
                }"
                    :totalRows="applications_records"
                    @on-page-change="onPageChangeApplications">
                    <template slot="table-row" slot-scope="props">
                        <div v-if="props.column.field === 'attributes.updated_at'">
                            <p style="text-transform: capitalize;">
                                {{ $moment(props.row.attributes.updated_at).format('HH:mm DD-MM-YYYY') }}</p>
                        </div>
                        <div v-else-if="props.column.field === 'attributes.status'">
                            <p style="text-transform: capitalize;">{{ props.row.attributes.status }}</p>
                        </div>
                        <div v-else-if="props.column.field === 'after'" class="td-after">
                            <Button v-if="$store.getters.hasPermission('update users')"
                                    className="--secondary --outline --mini --big-text"
                                    :onclick="()=>toggleViewApplication(props.row)">
                                <font-awesome-icon :icon="['far', 'eye']"/>
                            </Button>
                        </div>
                        <span v-else>
                      {{ props.formattedRow[props.column.field] }}
                    </span>
                    </template>
                </vue-good-table>
            </div>

            <div class="section-container">
                <h2>{{ $t('customers.email_audits') }}</h2>
                <vue-good-table
                    styleClass="vgt-table vgt-custom vgt-responsive-fix"
                    :columns="emailAuditsColumns"
                    :rows="email_audits"
                    :isLoading.sync="is_loading_email_audits"
                    :search-options="{
                    enabled: false,
                }"
                    :pagination-options="{
                    enabled: true,
                    mode: 'records',
                    dropdownAllowAll: false,
                    perPage: 15,
                    perPageDropdownEnabled: false,
                    rowsPerPageLabel: $t('x_per_page', {x: $t('customers.customers')}),
                }"
                    :sort-options="{
                  enabled: true,
                  multipleColumns: true,
                }"
                    :totalRows="applications_records"
                    @on-page-change="onPageChangeEmailAudits">
                    <template slot="table-row" slot-scope="props">
                        <div v-if="props.column.field === 'attributes.to'">
                            <p>{{ original.attributes.email }}</p>
                        </div>
                        <span v-else>
                      {{ props.formattedRow[props.column.field] }}
                    </span>
                    </template>
                </vue-good-table>
            </div>
        </main>
    </div>
</template>

<script>
import _ from 'lodash';
import Headbar from "../../components/headbar/Headbar";
import Form from "../../components/form/Form";
import SectionHeader from "../../components/SectionHeader";
import Button from "../../components/Button";
import FormInputText from "../../components/form/FormInputText";
import FormInputSelect from "../../components/form/FormInputSelect";
import {required, email} from 'vuelidate/lib/validators'
import FormGroupTwo from "../../components/form/FormGroupTwo";
import FormInputPassword from "../../components/form/FormInputPassword";
import ConfirmModal from "@/components/modal/ConfirmModal";
import FormInputDateTime from "../../components/form/FormInputDateTime";
import UsersUpdateModal from "../../components/users/UsersUpdateModal";
import ViewApplicationModal from "../../components/applications/ViewApplicationModal";


export default {
    name: "customers-update-page",
    components: {
        FormInputDateTime,
        FormGroupTwo, FormInputText, FormInputPassword, FormInputSelect, Button, SectionHeader, Form, Headbar
    },
    data: function () {
        let applicationsColumns = [
            {
                label: this.$t('applications.reference_number'),
                field: 'attributes.reference',
                sortable: false,
            },
            {
                label: this.$t('applications.status'),
                field: 'attributes.status',
                sortable: false,
            },
            {
                label: this.$t('applications.last_updated_at'),
                field: 'attributes.updated_at',
                sortable: false,
            },
        ];

        if (this.$store.getters.hasAnyPermission(['update servicepacks', 'destroy servicepacks']))
            applicationsColumns.push({
                label: this.$t('service_packs.actions'),
                field: 'after',
                tdClass: 'td-after',
                sortable: false
            });

        let emailAuditsColumns = [
            {
                label: this.$t('customers.from'),
                field: 'attributes.from_email',
                sortable: false,
            },
            {
                label: this.$t('customers.to'),
                field: 'attributes.to',
                sortable: false,
            },
            {
                label: this.$t('customers.message'),
                field: 'attributes.content',
                sortable: false,
            },

            {
                label: this.$t('customers.sent_at'),
                field: 'attributes.sent_at',
                sortable: false,
            },
        ];

        return {
            original: null,
            applicationsColumns: applicationsColumns,
            emailAuditsColumns: emailAuditsColumns,
            customer: {
                name: null,
                dob: null,
                phone: null,
                email: null,
            },
            is_loading: false,
            is_loading_applications: false,
            is_loading_email_audits: false,
            applications: [],
            email_audits: [],

            applicationsServerParams: {sorting: []},
            emailAuditsServerParams: {sorting: []},

            is_saving: false,
            applications_page: 1,
            email_audits_page: 1,
            applications_records: 0,
            email_audits_records: 0,


        }
    },
    validations: {
        customer: {
            name: {
                required
            },
            dob: {
                required
            },
            phone: {
                required
            },
            email: {
                required,
                email
            },
        }
    },
    methods: {
        async populate() {
            if (!this.original) return;


            this.$v.customer.name.$model = this.original.attributes.name;
            this.$v.customer.dob.$model = this.original.attributes.dob;
            this.$v.customer.phone.$model = this.original.attributes.phone;
            this.$v.customer.email.$model = this.original.attributes.email;
        },

        async retrieveOriginalCustomer() {
            this.is_loading = true

            await this.$axios.get(`users/${this.$route.params.id}`)
                .then(({data}) => {
                    this.original = data.data
                    this.applications = data.data.relationships.applications.data
                    this.email_audits = data.data.relationships.emails.data
                    this.is_loading = false
                })
                .catch(e => {
                    this.is_loading = false

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('users.error_retrieve')),
                        type: 'error',
                    });
                });

        },

        updateParams(newProps) {
            this.serverParams = Object.assign({}, this.serverParams, newProps);
        },

        onPageChange(params) {
            this.updateParams({page: params.currentPage});
            this.retrieveRoles();
        },

        onPageChangeApplications(){

        },

        onPageChangeEmailAudits(){

        },

        toggleViewApplication(props){
            this.$modal.show(
                ViewApplicationModal,
                {
                    application_id: props.id,
                },
                {
                    name: 'view-application-modal',
                    adaptive: true,
                    resizable: true,
                    height: 'auto',
                    width: '1000px',
                    scrollable: true,
                    classes: 'modal',
                }, {
                    'before-close': (e) => {
                        if (e.params === true){

                        }
                    }
                }
            );

        },

        save() {
            this.$v.customer.$touch();
            if (this.$v.customer.$anyError || this.is_saving)
                return;

            let payload = {
                name: this.customer.name,
                dob: this.customer.dob,
                phone: this.customer.phone,
            }

            if(this.original.attributes.email !== this.customer.email)
                payload['email'] = this.customer.email;

            this.is_saving = true;

            this.$axios.patch(`users/${this.$route.params.id}`, payload).then(({data}) => {
                this.$notify({
                    text: this.$t('users.success_updated'),
                    type: 'success',
                });
                this.is_saving = false;
                this.retrieveOriginalCustomer();
            }).catch(e => {
                this.is_saving = false;

                this.$notify({
                    title: this.$t('error'),
                    text: this.$larerror(e.response.data.errors, this.$t('users.error_update')),
                    type: 'error',
                });
            });
        },
    },

    async mounted() {
        await this.retrieveOriginalCustomer();
        await this.populate();
    },

    head() {
        return {
            title: {
                inner: this.$t('customers.customer')
            },
        }
    }
}
</script>

<style lang="scss" scoped>
.vgt-custom {
    @apply w-max rounded-t;
    width: max-content !important;
}

.page-container {

    .section-container {
        @apply border-b-2 border-primary py-7 px-8 w-full;


        h2 {
            @apply text-black font-bold text-2xl pb-4;
        }

    }

    main {
        @apply flex flex-col flex-wrap p-0;

        .section-container:last-child{
            @apply border-b-0;
        }

        .form {
            @apply mb-8 w-full;

            @screen lg {
                @apply mr-4;
                width: calc(50% - 1rem);
            }

            .form-body {
                @apply px-8 py-7;

                &.next-of-kin {
                    .next-of-kin-row-group {
                        .input-group {
                            @apply w-auto flex-1;
                        }

                        .button-remove {
                            @apply flex-none self-start mt-9;
                        }
                    }
                }
            }

            .row-add-container {
                @apply px-8 pb-8 mt-auto;
            }
        }

        .table {
            @apply w-full;

            @screen lg {
                @apply ml-4;
                width: calc(100% - 1rem);
            }

            &:nth-child(odd) {
                @screen lg {
                    @apply mr-4;
                }
            }

            &:nth-child(even) {
                @screen lg {
                    @apply ml-4;
                }
            }

            .td-after {
                @apply flex;

                svg {
                    @apply text-negative text-lg cursor-pointer ml-auto;

                    &:hover {
                        @apply text-primary-over
                    }
                }
            }
        }
    }
}
</style>