<template>
    <div class="page-container">
        <Headbar>
            <template v-slot:right>
                <Search class="search-desktop" :placeholder="$t('applications.search_applications')" @search="search"/>
                <button class="btn-search-mobile btn-icon-only"
                        @click="headbarExpanded = headbarExpanded === 'search' ? null : 'search'">
                    <font-awesome-icon v-if="headbarExpanded === 'search'" :icon="['far', 'times']"/>
                    <font-awesome-icon v-else :icon="['far', 'search']"/>
                </button>
            </template>

            <template v-slot:expanded>
                <div class="headbar-expanded-container">
                    <Search v-if="headbarExpanded === 'search'" :placeholder="$t('applications.search_applications')" @search="search"/>
                </div>
            </template>
        </Headbar>
        <main>
            <vue-good-table
                mode="remote"
                styleClass="vgt-table vgt-custom"
                :columns="columns"
                :rows="applications"
                :isLoading.sync="is_loading_applications"
                :search-options="{
                    enabled: false,
                }"
                :pagination-options="{
                    enabled: true,
                    mode: 'records',
                    dropdownAllowAll: false,
                    perPage: 15,
                    perPageDropdownEnabled: false,
                    rowsPerPageLabel: $t('x_per_page', {x: $t('applications.applications')}),
                }"
                :sort-options="{
                  enabled: true,
                  multipleColumns: true,
                }"
                :totalRows="totalRecords"
                @on-page-change="onPageChange"
                @on-sort-change="onSortChange">
                <template slot="table-row" slot-scope="props">

                    <div v-if="props.column.field === 'attributes.status'">
                        <p style="text-transform: capitalize;" v-if="props.row.attributes.status === 'documents_filed'">{{( $t('applications.documents_filed')) }}</p>
                        <p style="text-transform: capitalize;" v-else-if="props.row.attributes.status === 'require_information'">{{( $t('applications.require_information')) }}</p>
                        <p style="text-transform: capitalize;" v-else-if="props.row.attributes.status === 'kyc_verification'">{{( $t('applications.kyc_verification')) }}</p>


                        <p style="text-transform: capitalize;" v-else>{{(props.row.attributes.status) }}</p>
                    </div>
                    <div v-else-if="props.column.field === 'attributes.updated_at'">
                        <p style="text-transform: capitalize;">
                            {{ $moment(props.row.attributes.updated_at).format('HH:mm DD-MM-YYYY') }}
                        </p>
                    </div>
                    <div v-else-if="props.column.field === 'after'" class="td-after">
                        <router-link :to="{name: 'applications-update', params: {id: props.row.id}}" v-if="$store.getters.hasPermission('update applications')">
                            <Button
                                    className="--secondary --outline --mini --big-text">
                                <font-awesome-icon :icon="['fal', 'pencil']"/>
                            </Button>
                        </router-link>

                        <Button className="--secondary --outline --mini --big-text" v-if="$store.getters.hasPermission('destroy applications')"
                                :onclick="()=>toggleDelete(props.row)">
                            <font-awesome-icon :icon="['fal', 'trash']"/>
                        </Button>
                    </div>
                    <span v-else>
                      {{ props.formattedRow[props.column.field] }}
                    </span>
                </template>
            </vue-good-table>
        </main>
    </div>
</template>

<script>
import Headbar from "../../components/headbar/Headbar";
import Search from "../../components/Search";
import Button from "../../components/Button";
import Select from "../../components/form/Select";
import ConfirmModal from "../../components/modal/ConfirmModal";

export default {
    name: "applications-index-page",
    components: {ConfirmModal, Select, Button, Search, Headbar},
    data: function () {
        const columns = [
            {
                label: this.$t('applications.reference_number'),
                field: 'attributes.reference',
                sortable: false,
            },
            {
                label: this.$t('applications.status'),
                field: 'attributes.status',
                sortable: false,
            },
            {
                label: this.$t('applications.last_updated_at'),
                field: 'attributes.updated_at',
                sortable: false,
            },

            {
                label: this.$t('applications.client'),
                field: 'relationships.user.data.attributes.name',
                sortable: false,
            },
        ];

        if (this.$store.getters.hasAnyPermission(['update applications', 'destroy applications']))
            columns.push({
                label: this.$t('applications.actions'),
                field: 'after',
                tdClass: 'td-after',
                sortable: false
            });

        return {
            columns: columns,
            applications: [],
            is_loading_applications: false,
            totalRecords: null,
            serverParams: {sorting: []},
            searchTerm: null,
            is_deleting: false,
            headbarExpanded: false
        }
    },
    methods: {
        toggleDelete(application) {
            this.$modal.show(
                ConfirmModal, {
                    title: this.$t('applications.delete_application'),
                    message: this.$t('applications.prompt_delete'),
                    confirmText: this.$t('delete'),
                    cancelText: this.$t('cancel'),
                    confirmClass: '--primary',
                    cancelClass: '--secondary --outline'
                },
                {
                    name: 'confirm-modal',
                    adaptive: true,
                    resizable: true,
                    height: 'auto',
                    scrollable: true,
                    classes: 'modal',
                }, {
                    'before-close': (e) => {
                        if (e.params === true) {
                            this.is_deleting = true;
                            this.$axios.delete(`applications/${application.id}`)
                                .then(({data}) => {
                                    this.is_deleting = false;
                                    this.retrieveApplications();
                                })
                                .catch(e => {
                                    this.is_deleting = false;

                                    this.$notify({
                                        title: this.$t('error'),
                                        text: this.$larerror(e.response.data, this.$t('applications.error_delete')),
                                        type: 'error',
                                    });
                                });
                        }
                    }
                }
            );
        },
        updateParams(newProps) {
            this.serverParams = Object.assign({}, this.serverParams, newProps);
        },
        onPageChange(params) {
            this.updateParams({page: params.currentPage});
            this.retrieveApplications();
        },
        onSortChange(params) {
            const sorts = [];

            if (!params || !params.length) {
                this.updateParams({sorting: []});
                return this.retrieveApplications();
            }

            params.forEach(p => {
                if (!p.type || p.type === 'none')
                    return;

                let sort_by = null;
                let sort_order = p.type;
                if (p.field === 'attributes.name')
                    sort_by = 'name';
                else if (p.field === 'attributes.email')
                    sort_by = 'email';
                else if (p.field === 'role')
                    sort_by = 'role';
                else
                    sort_by = p.field.split('.')[1];

                sorts.push({sort_order, sort_by})
            });

            this.updateParams({sorting: sorts});
            this.retrieveApplications();
        },
        removeParam(param) {
            this.$delete(this.serverParams, param);
        },
        search(searchTerm) {
            this.searchTerm = searchTerm;

            if (searchTerm && searchTerm.length)
                this.updateParams({term: searchTerm});
            else this.removeParam('term');

            this.retrieveApplications();
        },
        retrieveApplications() {
            this.is_loading_applications = true;

            const encodedSorting = this.serverParams.sorting.map(f => btoa(JSON.stringify(f)));

            this.$axios.get('applications', {params: {...this.serverParams, sorting: encodedSorting}})
                .then(({data}) => {
                    this.applications = data.data;
                    this.totalRecords = data.meta.total;
                    this.is_loading_applications = false;
                })
                .catch(e => {
                    this.is_loading_applications = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('applications.error_retrieve')),
                        type: 'error',
                    });
                });
        }
    },

    mounted() {
        this.retrieveApplications();
    },

    head() {
        return {
            title: {
                inner: this.$t('nav.applications')
            },
        }
    }
}
</script>

<style lang="scss" scoped>
.page-container {
    .headbar-expanded-container {
        @apply mt-4 flex flex-col gap-y-4 items-center;

        @screen md {
            @apply hidden;
        }
    }

    .select-dropdown {
        @apply min-w-40;
    }

    .btn-search-mobile, .btn-filter-mobile {
        @apply block text-primary w-6;

        @screen md {
            @apply hidden;
        }

        &:active, &:focus {
            @apply outline-none;
        }
    }

    .search-desktop, .filter-desktop {
        @apply hidden;

        @screen md {
            @apply block;
        }
    }

    .search-container {
        @apply mr-0;
    }

    .filter-mobile {
        @apply max-w-xs;
    }

    .td-after {
        @apply flex flex-row;

        & > * {
            @apply mr-3;

            &:last-child {
                @apply mr-0;
            }
        }
    }
}
</style>
