<template>
    <div class="page-container" v-if="!is_loading">
        <Headbar>
            <template v-slot:left>
                <h1 v-if="original">{{ original.attributes.reference }}</h1>
            </template>
            <template v-slot:right>
                <div class="status-select-container">
                    <p>{{ $t('applications.status') }}</p>
                    <FormInputSelect v-model="application_status" :placeholder="$t('applications.status')"
                                     :options="statuses" identifier="name" :disabled="is_saving"
                                     track-by="name" :display-custom-label="(row) => `${row.name}`"/>
                </div>
                <Button v-if="$store.getters.hasPermission('update users')" className="--secondary --small --wider"
                        :class="{spinner: is_saving}" :onclick="save">
                    {{ $t('save') }}
                </Button>
            </template>
        </Headbar>
        <main class="main-container">
            <div class="content-container right">
                <div class="section-container">
                    <SectionHeader :title="$t('applications.application')"></SectionHeader>
                    <div class="form-content">
                        <ApplicationForm1 :application_data="parsed"></ApplicationForm1>
                        <ApplicationForm2 :application_data="parsed"></ApplicationForm2>
                        <ApplicationForm3 :application_data="parsed"></ApplicationForm3>
                        <ApplicationForm4 :application_data="parsed"></ApplicationForm4>
                        <ApplicationForm5 :application_data="parsed"></ApplicationForm5>
                        <ApplicationForm6 :application_data="parsed"></ApplicationForm6>
                        <ApplicationForm7 :application_data="parsed"></ApplicationForm7>
                        <ApplicationForm8 :application_data="parsed"></ApplicationForm8>
                    </div>
                </div>
            </div>

            <div class="content-container left">
                <div class="information-container">
                    <div class="documents-container">
                        <div class="title-container">
                            <h2>{{ $t('applications.documents') }}</h2>
                            <Button v-if="$store.getters.hasPermission('update users')"
                                    className="--secondary --small --wider"
                                    :class="{spinner: is_saving}" :onclick="showUploadModal">
                                <span>{{ $t('upload') }}</span>
                            </Button>
                        </div>
                        <vue-good-table
                            styleClass="vgt-table vgt-custom"
                            :columns="documentsColumns"
                            :rows="documents"
                            :isLoading.sync="is_loading"
                            :search-options="{enabled: false,}"
                            :pagination-options="{
                                enabled: true,
                                mode: 'records',
                                dropdownAllowAll: false,
                                perPage: 10,
                                perPageDropdownEnabled: false,
                                rowsPerPageLabel: $t('x_per_page', {x: $t('customers.customers')}),}"
                            :sort-options="{
                              enabled: true,
                              multipleColumns: true,
                            }"
                            :totalRows="documents_records"
                            @on-page-change="onPageChangeDocuments">
                            <template slot="table-row" slot-scope="props">
                                <div v-if="props.column.field === 'after'" class="td-after">
                                    <Button v-if="$store.getters.hasPermission('update users')"
                                            className="--secondary --outline --mini --big-text"
                                            :onclick="()=>downloadDocument(props.row)">
                                        <font-awesome-icon :icon="['far', 'download']"/>
                                    </Button>
                                    <Button v-if="$store.getters.hasPermission('destroy users')"
                                            className="--secondary --outline --mini --big-text"
                                            :onclick="()=>showDeleteDocumentModal(props.row)">
                                        <font-awesome-icon :icon="['fal', 'trash']"/>
                                    </Button>
                                </div>
                                <span v-else>{{ props.formattedRow[props.column.field] }}</span>
                            </template>
                        </vue-good-table>
                    </div>
                    <div class="divider"></div>
                    <div class="documents-container">
                        <h2>{{ $t('applications.audits') }}</h2>
                        <vue-good-table
                            mode="remote"
                            styleClass="vgt-table vgt-custom"
                            :columns="auditsColumns"
                            :rows="audits"
                            :isLoading.sync="is_loading_audits"
                            :search-options="{enabled: false,}"
                            :pagination-options="{
                                enabled: true,
                                mode: 'records',
                                dropdownAllowAll: false,
                                perPage: 15,
                                perPageDropdownEnabled: false,
                                rowsPerPageLabel: $t('x_per_page', {x: $t('customers.customers')}),}"
                            :sort-options="{
                              enabled: true,
                              multipleColumns: true,
                            }"
                            :totalRows="audits_records"
                            @on-page-change="onAuditsPageChange">
                            <template slot="table-row" slot-scope="props">
                                <div v-if="props.column.field === 'attributes.event'">
                                    <p style="text-transform: capitalize;">
                                        {{ props.row.attributes.event }}</p>
                                </div>
                                <div v-else-if="props.column.field === 'attributes.new_values.status'">
                                    <p style="text-transform: capitalize;" v-if="props.row.attributes.new_values.status === 'documents_filed'">{{( $t('applications.documents_filed')) }}</p>
                                    <p style="text-transform: capitalize;" v-else-if="props.row.attributes.new_values.status === 'require_information'">{{( $t('applications.require_information')) }}</p>
                                    <p style="text-transform: capitalize;" v-else-if="props.row.attributes.new_values.status === 'kyc_verification'">{{( $t('applications.kyc_verification')) }}</p>
                                    <p style="text-transform: capitalize;" v-else>{{ props.row.attributes.new_values.status }}</p></div>
                                <div v-else-if="props.column.field === 'detail'" class="td-values">
                                    <div v-if="Object.keys(props.row.attributes.old_values).length" class="row">
                                        <p class="name"><em>{{ $t('applications.old_values') }}</em></p>
                                        <div class="values">
                                            <p v-for="key in Object.keys(props.row.attributes.old_values)">
                                                <span v-if="key === 'value' && props.row.attributes.old_values.value">
                                                </span>
                                                <span v-else-if="key === 'parsedValue' && props.row.attributes.old_values.parsedValue">
                                                    <span v-for="parsedKey in Object.keys(props.row.attributes.old_values.parsedValue)">
                                                        <span v-if="parsedKey === 'directors' || parsedKey === 'shareHolders'">
                                                            <span>
                                                                <strong>{{$t(`applications.${parsedKey}`) }}:</strong>
                                                                <span v-for="personKey in (props.row.attributes.old_values.parsedValue[parsedKey])">
                                                                    {{ personKey }}</br>
                                                                </span>
                                                            </span>
                                                        </span>
                                                        <span v-else>
                                                            <strong>{{$t(`applications.${parsedKey}`) }}:</strong> {{ props.row.attributes.old_values.parsedValue[parsedKey] }}
                                                            </br>
                                                        </span>
                                                    </span>

                                                </span>
                                                <span v-else><strong>{{$t(`applications.${key}`) }}:</strong> {{ props.row.attributes.old_values[key] }}</span>
                                            </p>
                                        </div>
                                    </div>
                                    </br>

                                    <div v-if="Object.keys(props.row.attributes.new_values).length" class="row">
                                        <p class="name"><em>{{ $t('applications.new_values') }}</em></p>
                                        <div class="values">
                                            <p v-for="key in Object.keys(props.row.attributes.new_values)">
                                                <span v-if="key === 'value' && props.row.attributes.new_values.value">
                                                </span>
                                                <span v-else-if="key === 'parsedValue' && props.row.attributes.new_values.parsedValue">
                                                    <span v-for="parsedKey in Object.keys(props.row.attributes.new_values.parsedValue)">
                                                        <span v-if="parsedKey === 'directors' || parsedKey === 'shareHolders'">
                                                            <span>
                                                                <strong>{{$t(`applications.${parsedKey}`) }}:</strong>
                                                                <span v-for="personKey in (props.row.attributes.new_values.parsedValue[parsedKey])">
                                                                    {{ personKey }}</br>
                                                                </span>
                                                            </span>
                                                        </span>
                                                        <span v-else>
                                                            <strong>{{$t(`applications.${parsedKey}`) }}:</strong> {{ props.row.attributes.new_values.parsedValue[parsedKey] }}
                                                            </br>
                                                        </span>
                                                    </span>

                                                </span>
                                                <span v-else><strong>{{$t(`applications.${key}`) }}:</strong> {{ props.row.attributes.new_values[key] }}</span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <span v-else>{{ props.formattedRow[props.column.field] }}</span>
                            </template>
                        </vue-good-table>
                    </div>

                </div>
            </div>
            <div class="divider"></div>


        </main>
    </div>
</template>

<script>
import _ from 'lodash';
import Headbar from "../../components/headbar/Headbar";
import Form from "../../components/form/Form";
import SectionHeader from "../../components/SectionHeader";
import Button from "../../components/Button";
import FormInputText from "../../components/form/FormInputText";
import FormInputSelect from "../../components/form/FormInputSelect";
import {required, email} from 'vuelidate/lib/validators'
import FormGroupTwo from "../../components/form/FormGroupTwo";
import FormInputPassword from "../../components/form/FormInputPassword";
import ConfirmModal from "@/components/modal/ConfirmModal";
import FormInputDateTime from "../../components/form/FormInputDateTime";
import ApplicationForm1 from "../../components/applications/ApplicationForm1";
import ApplicationForm2 from "../../components/applications/ApplicationForm2";
import ApplicationForm3 from "../../components/applications/ApplicationForm3";
import ApplicationForm4 from "../../components/applications/ApplicationForm4";
import ApplicationForm5 from "../../components/applications/ApplicationForm5";
import ApplicationForm6 from "../../components/applications/ApplicationForm6";
import ApplicationForm7 from "../../components/applications/ApplicationForm7";
import ApplicationForm8 from "../../components/applications/ApplicationForm8";
import UploadDocumentModal from "../../components/applications/UploadDocumentModal";
import ServicesCreateModal from "../../components/services/ServicesCreateModal";

export default {
    name: "applications-update-page",
    components: {
        FormInputDateTime,
        FormGroupTwo,
        FormInputText,
        FormInputPassword,
        FormInputSelect,
        Button,
        SectionHeader,
        Form,
        Headbar,
        ApplicationForm1,
        ApplicationForm2,
        ApplicationForm3,
        ApplicationForm4,
        ApplicationForm5,
        ApplicationForm6,
        ApplicationForm7,
        ApplicationForm8
    },
    data: function () {
        const documentsColumns = [
            {
                label: this.$t('applications.name'),
                field: 'attributes.name',
                sortable: false,
            },
        ];

        const auditsColumns = [
            {
                label: this.$t('applications.event'),
                field: 'attributes.event',
                sortable: false,
            },
            {
                label: this.$t('applications.status'),
                field: 'attributes.new_values.status',
                sortable: false,
            },
            {
                label: this.$t('applications.detail'),
                field: 'detail',
                sortable: false,
            },
        ];

        // if (this.$store.getters.hasAnyPermission(['update applications', 'destroy applications']))
        documentsColumns.push({
            label: this.$t('applications.actions'),
            field: 'after',
            tdClass: 'td-after',
            sortable: false
        });

        return {
            original: null,
            parsed: null,
            application: null,
            statuses: [],
            documents: [],
            audits: [],
            auditsServerParams: {sorting: []},
            documentsServerParams: {sorting: []},
            documentsColumns: documentsColumns,
            auditsColumns: auditsColumns,
            is_loading: true,
            is_loading_documents: false,
            is_loading_audits: false,
            is_downloading: false,
            is_saving: false,
            application_status: null,
            documents_page: 1,
            audits_page: 1,
            documents_records: null,
            audits_records: null,
        }
    },
    methods: {
        async populate() {
            if (!this.original) return;
        },

        updateParams(newProps) {
            this.serverParams = Object.assign({}, this.serverParams, newProps);
        },

        onPageChangeDocuments(params) {
            this.documents_page = params.currentPage
        },

        updateAuditsParams(newProps) {
            this.auditsServerParams = Object.assign({}, this.auditsServerParams, newProps);
        },

        onAuditsPageChange(params) {
            this.updateAuditsParams({page: params.currentPage});
            this.retrieveApplicationAudits();
        },

        onSortChange(params) {
            let sort_by = params[0].field.split('.')[1];
            this.updateParams({sort_by: sort_by, sort_order: params[0].type});
            this.retrieveRoles();
        },

        removeParam(param) {
            this.$delete(this.serverParams, param);
        },

        async retrieveStatusList() {
            this.is_loading = true

            await this.$axios.get(`applications/list-statuses`)
                .then(({data}) => {
                    this.statuses = data
                })
                .catch(e => {
                    this.is_loading = false
                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('applications.error_retrieve')),
                        type: 'error',
                    });
                });


        },

        async retrieveOriginalApplication() {
            this.is_loading = true

            await this.$axios.get(`applications/${this.$route.params.id}`)
                .then(({data}) => {
                    this.original = data.data
                    this.parsed = JSON.parse(this.original.attributes.value)
                    this.documents = data.data.relationships.documents.data
                    this.documents_records = data.data.relationships.documents.data.length
                    this.application_status = _.find(this.statuses, {column: this.original.attributes.status});
                    this.is_loading = false;
                    console.log(this.parsed)
                })
                .catch(e => {
                    this.is_loading = false

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('applications.error_retrieve')),
                        type: 'error',
                    });
                });
        },

        async retrieveApplicationAudits() {
            this.is_loading_audits = true
            await this.$axios.get(`applications/${this.original.id}/audits`, {params: {...this.auditsServerParams}})
                .then(async ({data}) => {
                    this.audits = data.data
                    this.audits_records = data.meta.total
                    await this.parseApplicationAudits();
                    this.is_loading_audits = false;
                })
                .catch(e => {
                    this.is_loading_audits = false;
                    console.log(e)

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('applications.error_retrieve')),
                        type: 'error',
                    });
                });

        },

        async parseApplicationAudits() {
            this.audits.forEach(audit => {

                if (audit.attributes?.old_values?.value && audit.attributes?.new_values?.value) {
                    let parsedOld = JSON.parse(audit.attributes.old_values.value)
                    let parsedNew = JSON.parse(audit.attributes.new_values.value)

                    let parsedOldString = {}
                    let parsedNewString = {}

                    Object.keys(parsedNew).forEach(key => {
                        console.log(parsedNew)

                        if (key === 'names') {
                            if(!parsedOld[key]){
                                let tempStringNew = ''

                                parsedOldString[key] = "N/A";
                                parsedNew[key].forEach(name => {
                                    tempStringNew += `${name.name} ${name.ltd}, `
                                })

                                parsedNewString[key] = tempStringNew.slice(0, -2);
                            }

                            else{
                                let tempStringOld = ''
                                let tempStringNew = ''

                                parsedOld[key].forEach(name => {
                                    tempStringOld += `${name.name} ${name.ltd}, `
                                })

                                parsedNew[key].forEach(name => {
                                    tempStringNew += `${name.name} ${name.ltd}, `
                                })

                                if(tempStringOld === tempStringNew){
                                }

                                else {
                                    parsedOldString[key] = tempStringOld.slice(0, -2);
                                    parsedNewString[key] = tempStringNew.slice(0, -2);
                                }
                            }
                        }

                        else if (key === 'addons') {
                            if(!parsedOld[key]){
                                let tempStringNew = ''

                                parsedOldString[key] = "N/A";
                                parsedNew[key].forEach(addon => {
                                    tempStringNew += `${addon.name} - ${addon.price}, `
                                })

                                parsedNewString[key] = tempStringNew.slice(0, -2);
                            }

                            else{
                                let tempStringOld = ''
                                let tempStringNew = ''

                                parsedOld[key].forEach(addon => {
                                    tempStringOld += `${addon.name} - ${addon.price}, `
                                })

                                parsedNew[key].forEach(addon => {
                                    tempStringNew += `${addon.name} - ${addon.price}, `
                                })

                                if(tempStringOld === tempStringNew){
                                }

                                else {
                                    parsedOldString[key] = tempStringOld.slice(0, -2);
                                    parsedNewString[key] = tempStringNew.slice(0, -2);
                                }
                            }
                        }

                        else if (key === 'address') {
                            if(!parsedOld[key]){
                                let tempStringNew = ''

                                parsedOldString[key] = "N/A";
                                if(parsedNew.address){
                                    tempStringNew += `${parsedNew.address.building}, ${parsedNew.address.street}, ${parsedNew.address.locality}, ${parsedNew.address.post_code}, `
                                    if(parsedNew.address.country && parsedNew.address.country.text)
                                        tempStringNew += `${parsedNew.address.country.text}`
                                    else
                                        tempStringNew += `null`
                                }
                                parsedNewString[key] = tempStringNew;
                            }

                            else{
                                let tempStringOld = ''
                                let tempStringNew = ''

                                if(parsedOld.address){
                                    tempStringOld += `${parsedOld.address.building}, ${parsedOld.address.street}, ${parsedOld.address.locality}, ${parsedOld.address.post_code}, `
                                    if(parsedOld.address.country && parsedOld.address.country.text)
                                        tempStringOld += `${parsedOld.address.country.text}`
                                    else
                                        tempStringOld += `null`
                                }

                                if(parsedNew.address){
                                    tempStringNew += `${parsedNew.address.building}, ${parsedNew.address.street}, ${parsedNew.address.locality}, ${parsedNew.address.post_code}, `
                                    if(parsedNew.address.country && parsedNew.address.country.text)
                                        tempStringNew += `${parsedNew.address.country.text}`
                                    else
                                        tempStringNew += `null`
                                }


                                if(tempStringOld === tempStringNew){
                                }

                                else {
                                    parsedOldString[key] = tempStringOld;
                                    parsedNewString[key] = tempStringNew;
                                }
                            }
                        }

                        else if (key === 'representer') {
                            if(!parsedOld[key]){
                                let tempStringNew = ''

                                parsedOldString[key] = "N/A";
                                tempStringNew += `${parsedNew.representer.name} - ${parsedNew.representer.independent_jointly.name}`

                                parsedNewString[key] = tempStringNew;
                            }

                            else{
                                let tempStringOld = ''
                                let tempStringNew = ''

                                tempStringOld += `${parsedOld.representer.name} - ${parsedOld.representer.independent_jointly.name}`
                                tempStringNew += `${parsedNew.representer.name} - ${parsedNew.representer.independent_jointly.name}`


                                if(tempStringOld === tempStringNew){
                                }

                                else {
                                    parsedOldString[key] = tempStringOld;
                                    parsedNewString[key] = tempStringNew;
                                }
                            }
                        }

                        else if (key === 'secretary') {
                            if(!parsedOld[key]){
                                let tempStringNew = ''

                                parsedOldString[key] = "N/A";
                                tempStringNew += (`${parsedNew.secretary.name}, ${parsedNew.secretary.email}, ${parsedNew.secretary.nationality}, ${parsedNew.secretary.dob}, ${parsedNew.secretary.email}, ${parsedNew.secretary.address.residence}, ${parsedNew.secretary.address.street}, ${parsedNew.secretary.address.locality}, ${parsedNew.secretary.address.post_code}, ${parsedNew.secretary.address.country}`)

                                parsedNewString[key] = tempStringNew;
                            }

                            else{
                                let tempStringOld = ''
                                let tempStringNew = ''

                                tempStringOld += (`${parsedOld.secretary.name}, ${parsedOld.secretary.email}, ${parsedOld.secretary.nationality}, ${parsedOld.secretary.dob}, ${parsedOld.secretary.email}, ${parsedOld.secretary.address.residence}, ${parsedOld.secretary.address.street}, ${parsedOld.secretary.address.locality}, ${parsedOld.secretary.address.post_code}, ${parsedOld.secretary.address.country}`)
                                tempStringNew += (`${parsedNew.secretary.name}, ${parsedNew.secretary.email}, ${parsedNew.secretary.nationality}, ${parsedNew.secretary.dob}, ${parsedNew.secretary.email}, ${parsedNew.secretary.address.residence}, ${parsedNew.secretary.address.street}, ${parsedNew.secretary.address.locality}, ${parsedNew.secretary.address.post_code}, ${parsedNew.secretary.address.country}`)


                                if(tempStringOld === tempStringNew){
                                }

                                else {
                                    parsedOldString[key] = tempStringOld;
                                    parsedNewString[key] = tempStringNew;
                                }
                            }
                        }

                        else if (key === 'shareHolders' || key === 'directors') {
                            if(!parsedOld[key]){
                                let tempArray = []

                                parsedOldString[key] = ["N/A"];

                                parsedNew[key].forEach(person => {
                                    tempArray.push(person['parsedString'] = (`${person.name}, ${person.email}, ${person.nationality}, ${person.dob}, ${person.email}, ${person.address.residence}, ${person.address.street}, ${person.address.locality}, ${person.address.post_code}, ${person.address.country}`))
                                })
                                parsedNewString[key] = tempArray;
                            }

                            else{
                                let tempArrayNew = []
                                let tempArrayOld = []

                                parsedOld[key].forEach(person => {
                                    tempArrayOld.push(person['parsedString'] = (`${person.name}, ${person.email}, ${person.nationality}, ${person.dob}, ${person.email}, ${person.address.residence}, ${person.address.street}, ${person.address.locality}, ${person.address.post_code}, ${person.address.country}`))
                                })

                                parsedNew[key].forEach(person => {
                                    tempArrayNew.push(person['parsedString'] = (`${person.name}, ${person.email}, ${person.nationality}, ${person.dob}, ${person.email}, ${person.address.residence}, ${person.address.street}, ${person.address.locality}, ${person.address.post_code}, ${person.address.country}`))
                                })

                                if(tempArrayNew.toString() === tempArrayOld.toString()){

                                }

                                else {
                                    parsedOldString[key] = tempArrayOld;
                                    parsedNewString[key] = tempArrayNew;
                                }

                            }
                        }

                        else if (typeof parsedNew[key] === 'object') {
                            if(parsedNew[key] === null){

                            }

                            else if(!parsedOld[key]){
                                if(parsedNew[key].length){
                                    let tempStringNew = ''
                                    parsedOldString[key] = "N/A";

                                    parsedNew[key].forEach(name => {
                                        tempStringNew += `${name.name}, `
                                    })
                                    parsedNewString[key] = tempStringNew.slice(0, -2);
                                }

                                else{
                                    parsedOldString[key] = "N/A";
                                    parsedNewString[key] = parsedNew[key].name;
                                }
                            }

                            else{
                                if(parsedNew[key].length){
                                    let tempStringNew = ''
                                    let tempStringOld = ''

                                    parsedOld[key].forEach(name => {
                                        tempStringOld += `${name.name}, `
                                    })

                                    parsedNew[key].forEach(name => {
                                        tempStringNew += `${name.name}, `
                                    })

                                    if(tempStringOld === tempStringNew){

                                    }

                                    else {
                                        parsedOldString[key] = tempStringOld.slice(0, -2);
                                        parsedNewString[key] = tempStringNew.slice(0, -2);
                                    }
                                }

                                else{
                                    if(JSON.stringify(parsedOld[key].name) === JSON.stringify(parsedNew[key].name)){

                                    }

                                    else{
                                        parsedOldString[key] = parsedOld[key].name;
                                        parsedNewString[key] = parsedNew[key].name;
                                    }
                                }
                            }
                        }

                        else if (typeof parsedNew[key] === 'string') {
                            if(!parsedOld[key]){
                                parsedOldString[key] = "N/A";
                                parsedNewString[key] = parsedNew[key];
                            }

                            else{
                                if(JSON.stringify(parsedOld[key]) === JSON.stringify(parsedNew[key])){

                                }
                                else {
                                    parsedOldString[key] = parsedOld[key];
                                    parsedNewString[key] = parsedNew[key];
                                }
                            }
                        }
                    })
                    audit.attributes.old_values.parsedValue = parsedOldString
                    audit.attributes.new_values.parsedValue = parsedNewString
                } else {
                    if (!audit.attributes.old_values.value) {
                        audit.attributes.old_values['value'] = "N/A"
                    }
                }
            })
        },

        showUploadModal() {
            this.$modal.show(
                UploadDocumentModal,
                {
                    applicationId: this.original.id
                },
                {
                    name: 'upload-document-modal',
                    adaptive: true,
                    resizable: true,
                    height: 'auto',
                    scrollable: true,
                    classes: 'modal',
                }, {
                    'before-close': (e) => {
                        if (e.params === true)
                            this.retrieveOriginalApplication();
                        this.is_loading = false;
                    }
                }
            );
        },

        showDeleteDocumentModal(document) {
            this.$modal.show(
                ConfirmModal, {
                    title: this.$t('applications.delete_document'),
                    message: this.$t('applications.prompt_delete_document'),
                    confirmText: this.$t('delete'),
                    cancelText: this.$t('cancel'),
                    confirmClass: '--primary',
                    cancelClass: '--secondary --outline'
                },
                {
                    name: 'confirm-modal',
                    adaptive: true,
                    resizable: true,
                    height: 'auto',
                    scrollable: true,
                    classes: 'modal',
                }, {
                    'before-close': (e) => {
                        if (e.params === true) {
                            this.is_deleting = true;
                            this.$axios.delete(`documents/${document.id}`)
                                .then(({data}) => {
                                    this.is_deleting = false;
                                    this.retrieveOriginalApplication();
                                    this.is_loading = false;
                                })
                                .catch(e => {
                                    this.is_deleting = false;

                                    this.$notify({
                                        title: this.$t('error'),
                                        text: this.$larerror(e.response.data, this.$t('applications.error_delete_document')),
                                        type: 'error',
                                    });
                                });
                        }
                    }
                }
            );
        },

        async downloadDocument(props) {
            this.is_downloading = true;

            await this.$axios.get(`documents/${props.id}`, {
                responseType: 'arraybuffer'
            }).then(({data}) => {
                let fileExtension = this.$filetype(data).ext
                let fileName = props.attributes.name
                const url = window.URL.createObjectURL(new Blob([data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `${fileName}.${fileExtension}`);
                document.body.appendChild(link);
                link.click();
            })
                .catch(e => {
                    this.is_downloading = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('applications.error_download_document')),
                        type: 'error',
                    });
                });
        },

        save() {
            let payload = {
                status: this.application_status.column
            }

            this.$axios.patch(`applications/${this.$route.params.id}`, payload).then(({data}) => {
                this.$notify({
                    text: this.$t('applications.success_updated'),
                    type: 'success',
                });
                this.is_saving = false;
                this.retrieveOriginalApplication();
                this.is_loading = false;
            }).catch(e => {
                this.is_saving = false;

                this.$notify({
                    title: this.$t('error'),
                    text: this.$larerror(e.response.data.errors, this.$t('applications.error_update')),
                    type: 'error',
                });
            });
        },

    },

    async mounted() {
        await this.retrieveStatusList();
        await this.retrieveOriginalApplication();
        await this.retrieveApplicationAudits();
        await this.populate();
    },

    head() {
        return {
            title: {
                inner: this.$t('applications.application')
            },
        }
    }
}
</script>

<style lang="scss" scoped>
.vgt-custom {
    @apply w-max rounded-t;
    width: max-content !important;
}

.page-container {

    .main-container {
        @apply flex flex-col;

        @screen lg {
            @apply flex-row;
        }

        .content-container {
            @apply w-full py-7;

            &.left {
                @apply pl-0;

                @screen md {
                    @apply pl-4;
                }
            }

            &.right {
                @apply pr-0;

                @screen md {
                    @apply pr-4;
                }
            }

            @screen lg {
                @apply w-1/2
            }

            .section-container {
                @apply w-full;

                .form-content {
                    @apply p-10;

                    .section-title {
                        @apply text-black font-bold text-lg;
                        max-width: 75%;
                    }

                }
            }

            .information-container {

                .documents-container {

                    h2 {
                        @apply text-black text-xl font-bold mb-4;
                    }

                    .title-container {
                        @apply flex flex-row;

                        button {
                            @apply ml-auto w-24 h-8 flex items-center content-center justify-center;

                        }
                    }
                }

            }

            .divider {
                @apply border-b-2 border-primary mt-12 mb-12;
                width: 100%;
            }

        }
    }

    .status-select-container {
        @apply flex flex-row items-center;

        p {
            @apply mr-4 text-xs;
        }

        .input-group {
            @apply mb-0 w-40 mr-4;
        }
    }

    .td-after {
        @apply flex flex-row;

        & > * {
            @apply mr-3;

            &:last-child {
                @apply mr-0;
            }
        }
    }


}
</style>