import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersist from 'vuex-persist';

Vue.use(Vuex);

const vuexPersist = new VuexPersist({
    key: 'vdp',
    storage: localStorage,
    reducer: (state) => ({
        token: state.token,
        user: state.user,
    })
});


export default new Vuex.Store({
    plugins: [vuexPersist.plugin],
    state: {
        token: null,
        user: null,
        menuExpanded: false,
    },
    getters: {
        token: state => state.token,
        user: state => state.user,
        userPermissions: (state) => () => {
            return (state.user && state.user.relationships && state.user.relationships.permissions && state.user.relationships.permissions.data) || [];
        },
        hasPermission: (state, getters) => (permission) => {
            if (permission.constructor !== String)
                return false;

            return getters.userPermissions().some(p => p.attributes.name === permission);
        },
        hasAnyPermission: (state, getters) => (permissions) => {
            if (permissions.constructor !== Array)
                return false;

            return getters.userPermissions().some(p => permissions.includes(p.attributes.name));
        },
        hasAllPermissions: (state, getters) => (permissions) => {
            if (permissions.constructor !== Array)
                return false;

            return getters.userPermissions().filter(p => permissions.includes(p.attributes.name)).length === permissions.length;
        },
        userRoles: (state) => () => {
            return (state.user && state.user.relationships && state.user.relationships.roles && state.user.relationships.roles.data) || [];
        },
        hasRole: (state, getters) => (role) => {
            if (role.constructor !== String)
                return false;

            return getters.userRoles().some(p => p.attributes.name === role);
        },
        hasAnyRole: (state, getters) => (roles) => {
            if (roles.constructor !== Array)
                return false;

            return getters.userRoles().some(p => roles.includes(p.attributes.name));
        },
    },
    mutations: {
        SET_TOKEN(state, token) {
            state.token = token;
        },
        SET_USER(state, user) {
            state.user = user;
        },
        TOGGLE_MENU_EXPANDED(state, status = null){
            if(status !== null)
                state.menuExpanded = status;
            else state.menuExpanded = !state.menuExpanded;
        },
    },
    actions: {
        async getUser({commit, state}) {
            await this._vm.$axios.get('profile')
                .then(({data}) => {
                    commit("SET_USER", data.data);
                });
        },
        async logout({commit, state}) {
            await commit("SET_USER", null);
            await commit("SET_TOKEN", null);
        }
    }
});
