<template>
    <ApplicationFormBase :title="$t('applications.form_2_text')" :form_number="2">
        <Form class="form-container" v-if="has_address">
            <FormInputSelect
                v-model="has_address" :allow-empty="false"
                :label="$t('applications.yes_no')"
                class="has-registered-address" track-by="value" disabled>
            </FormInputSelect>
            <div v-if="has_address === 'Yes'">
                <FormInputText v-model="application_data.address.building"
                               :label="`${$t('applications.building_number_name')}`"
                               class="building" identifier="building" type="text" disabled>
                </FormInputText>

                <FormInputText v-model="application_data.address.street"
                               :label="`${$t('applications.street_name')}`"
                               class="street" identifier="street" type="text" disabled>
                </FormInputText>

                <FormInputText
                    v-model="application_data.address.locality"
                    :label="$t('applications.locality_city')"
                    class="locality" disabled>
                </FormInputText>

                <FormInputText v-model="application_data.address.post_code"
                               :label="`${$t('applications.post_code')}`" disabled>
                </FormInputText>

                <FormInputSelect
                    v-model="application_data.address.country" :allow-empty="false"
                    :label="$t('applications.country')"
                    class="country" display-label="text" track-by="value" disabled>
                </FormInputSelect>
            </div>
        </Form>
    </ApplicationFormBase>
</template>

<script>
import ApplicationFormBase from "./ApplicationFormBase";
import FormInputSelect from "../form/FormInputSelect";
import FormInputText from "../form/FormInputText";

export default {
    name: "ApplicationForm2",
    components: { ApplicationFormBase, FormInputSelect, FormInputText },
    props: {
        application_data: {
            type: Object,
            required: true,
        }
    },
    data() {
        return {
            has_address: "No",
        }
    },
    methods: {

    },
    mounted(){
        if(this.application_data.address) this.has_address = "Yes";
        else this.has_address = "No";
    }

}
</script>

<style lang="scss" scoped>

</style>