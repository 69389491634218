<template>
    <div class="application-form-container">
        <div class="title-container">
            <div class="number-container">
                <p>{{form_number}}</p>
            </div>
            <p class="title">{{title}}</p>
        </div>
        <div class="slot-container">
            <slot></slot>
        </div>
    </div>
</template>

<script>
import ModalContainer from "../modal/ModalContainer";
import Form from "../form/Form";
import FormInputText from "../form/FormInputText";
import FormGroupTwo from "../form/FormGroupTwo";
import FormInputSelect from "../form/FormInputSelect";
import {required, email} from 'vuelidate/lib/validators'
import Button from "../Button";
import FormInputPassword from "@/components/form/FormInputPassword";

export default {
    name: "ApplicationFormBase",
    components: {},
    props: {
        form_number: {
            type: Number,
            required: true,
        },
        title: {
            type: String,
            required: true
        }
    },

    data() {
        return {
        }
    },
    methods: {

    },

}
</script>

<style lang="scss" scoped>
.application-form-container{
    @apply mb-16;

    .title-container{
        @apply flex flex-row items-center mb-4;

        .number-container{
            @apply flex bg-primary text-white text-2xl rounded-full w-10 h-10 mb-auto;

            p{
                @apply m-auto;
            }
        }

        .title{
            @apply font-bold text-black text-xl ml-4;
            max-width:75%;
        }
    }

    .slot-container{
        @apply ml-14;
        width:75%;
    }

}

</style>