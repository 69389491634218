<template>
    <ApplicationFormBase :title="$t('applications.form_1_text')" :form_number="1">
        <Form class="form-container" v-if="application_data.names">
            <div v-for="(v, i) in application_data.names" :key="v.key"
                 class="name-row-container">
                <FormInputText v-model="v.name" :identifier="`name-${i}`"
                               :label="`${$t('applications.preference_x', {x: +i + 1})}`"
                               class="name" disabled
                               type="text">
                </FormInputText>
                <FormInputSelect
                    v-model="v.ltd" :allow-empty="false"
                    :label="$t('applications.limited_or_ltd')"
                    class="ltd" disabled
                    track-by="value">
                </FormInputSelect>
            </div>
        </Form>
    </ApplicationFormBase>
</template>

<script>
import ApplicationFormBase from "./ApplicationFormBase";
import FormInputText from "../form/FormInputText";
import FormInputSelect from "../form/FormInputSelect";

export default {
    name: "ApplicationForm1",
    components: { ApplicationFormBase, FormInputText, FormInputSelect},
    props: {
        application_data: {
            type: Object,
            required: true,
        }
    },
    data() {
        return {
            default_values : [{name: "", ltd : "ltd"}, {name: "", ltd : "ltd"}, {name: "", ltd : "ltd"},]
        }
    },
    methods: {

    },

    mounted(){
        if(!this.application_data.names){
            this.application_data.names = this.default_values
        }
    }
}
</script>

<style lang="scss" scoped>
.form-container {
    .name-row-container {
        @apply flex flex-col w-full gap-x-4;

        @screen md {
            @apply gap-x-12 flex-row;
        }

        .name {
            @apply w-2/3;
        }

        .ltd {
            @apply w-1/3;
        }
    }
}

</style>