export default {
    nav: {
        reset_password: 'Reset Password',
        applications: 'Applications',
        customers: 'Customers',
        services: 'Services',
        service_packs: 'Service Packs',
        roles_permissions: 'Roles & Permissions',
        users: 'Users',
        login: 'Login',
        logout: 'Log out',
        profile: 'My Profile',
    },
    articles: {
        actions: 'Actions',
        articles: 'articles',
        name: 'Name',
        description: 'Description',
        code: 'Code',
        category: 'Category',
        conversion_rate: 'Conversion Rate',
        price: 'Price',
        shipping_cost: 'Shipping Cost',
        mark_up: 'Mark-up',
        vat: 'VAT',
        add_article: 'Add Article',
        edit_article: 'Edit Article',
        delete_article: 'Delete Article',
        search_articles: 'Search Articles',
        update_article: 'Update Article',
        article_details: 'Article Details',
        error_retrieve: 'Failed to retrieve articles',
        error_delete: 'Failed to delete article',
        prompt_delete: 'Are you sure you want to delete this article?',
        success_created: 'Article has been created',
        error_create: 'Failed to create article',
        edit_category: 'Edit Article',
        success_updated: 'Article has been updated',
        error_update: 'Failed to update article'
    },
    audits: {
        audits: 'Audits',
        user: 'User',
        event: 'Event',
        date_n_time: 'Date & Time',
        values: 'Values',
        old_values:'Old Values',
        new_values:'New Values',
        updated: 'Updated',
        created: 'Created',
        error_retrieve_audits: 'Failed to retrieve audits',
    },
    applications: {
        application: 'Application',
        applications: 'Applications',
        reference_number: 'Reference Number',
        status: 'Status',
        last_updated: 'Last updated',
        last_updated_at: 'Last updated at',
        client: 'Client',
        actions: 'Actions',
        documents: 'Documents',
        document: 'Document',
        document_name:'Document name',
        upload_document: 'Upload Document',
        choose_file: 'Choose File',
        please_choose_a_file: 'Please choose a file',
        invalid_file_type: 'Invalid file type, please choose a .jpg or .pdf file',
        file_types_supported: 'File types supported: .jpg / .pdf',
        audits: 'Audits',
        name: 'Name',
        event: 'Event',
        detail: 'Detail',
        documents_filed: 'Documents Filed',

        form_1_text: 'Please provide three company names, in order of preference. In your response, please choose between Limited or Ltd.',
        preference_x: "Preference {x}",
        limited_or_ltd: "Limited or Ltd.",

        form_2_text: 'Does your business have a registered address?',
        yes_no: 'Yes or no',
        building_number_name: 'Building number / name',
        street_name: 'Street name',
        locality_city: 'Locality / City',
        post_code: 'Post Code',
        country: 'Country',

        form_3_text: 'Please outline the nature of the business activity, the countries where the business will operate, and how the business will be funded.',
        nature_of_business_activity: 'Nature of business activity',
        countries_of_operation: 'Countries of operation',
        financing_information: 'Activities that will be funded',

        form_4_text: 'Please provide the following information in relation to the shareholding of the company.',
        authorized_shares_company: "How many authorized shares will the company have?",
        authorized_shares_company_tt: "This relates to the total number of shares which may be issued by the company. For e.g., 10,000 shares.",
        issued_shares_company: "How many issued shares will the company have?",
        issued_shares_company_tt: "Issued shares are shares which have been subscribed by the shareholders. The minimum number of issued shares required under Maltese law is 1,200 shares.",
        percentage_capital_paid: "What percentage of the issued share capital will be paid up?",
        percentage_capital_paid_tt: "Paid up shares are shares which have been paid for by the company’s shareholders. Maltese law requires that a minimum of 20% of the share capital must be paid up.",
        currency_shares_issued: "What currently will the shares be issued in?",
        currency_shares_issued_tt: "This may be any currency.",
        nominal_value_share: "What is the nominal value of each share?",
        nominal_value_share_tt: "This relates to the value attributed to each share, for e.g EUR 1 per share.",

        form_5_text: 'Please outline the number of shareholders the company will have.',
        number_of_shareholders: "Number of share holders",
        shareholder_x_of_n: "Shareholder {x} / {n}",
        full_name: "Full name",
        nationality: "Nationality",
        date_of_birth: "Date of birth",
        email: "Email",
        percentage_shareholding: "Percentage shareholding",

        form_6_text: 'Please outline the number of directors the company will have.',
        director_x_of_n: "Director {x} / {n}",
        number_of_directors: "Number of directors",

        form_7_text: 'Please provide the details of the person vested with legal representation of the company.',
        independent_jointly: "Independently or jointly",

        form_8_text: 'Please provide details on the Company Secretary.',

        values: 'Values',
        old_values: 'Old values',
        new_values: 'New values',
        addons: 'Addons',
        address: 'Address',
        authorizedShares: 'Authorized Shares',
        countriesOperation: 'Countries of operation',
        sharesCurrency: 'Currency of shares',
        directors: 'Directors',
        financingInformation: 'Financing Information',
        issuedShares: 'Issued Shares',
        names: 'Names of preferred companies',
        natureBusiness: 'Nature of business',
        natureBusinessOther: 'Nature of business other',
        numDirectors: 'Number of directors',
        numShareHolders: 'Number of shareholders',
        percentageCapitalPaid: 'Percentage of capital paid',
        hasRegisteredAddress: 'Business has registered address',
        representer: 'Representer',
        secretary: 'Secretary',
        shareHolders: 'Shareholders',
        shareCurrency: 'Share currency',
        valueEachShare: 'Value of each share',
        submitted_at: 'Submitted at',
        user_id: 'User id',
        value: 'Value',
        id: 'Id',
        reference: 'Reference',
        no_applications_found: "No applications found",
        require_information : "Require Information",
        kyc_verification : "Kyc Verification",


        add_application: 'Add Application',
        edit_application: 'Edit Application',
        delete_application: 'Delete Application',
        search_applications: 'Search applications',
        update_application: 'Update Application',
        application_details: 'Application Details',
        delete_document: 'Delete Document',
        prompt_delete_document: 'Are you sure you want to delete this document?',
        error_download_document: 'Failed to download document',
        error_delete_document: 'Failed to delete document',
        error_retrieve: 'Failed to retrieve applications',
        error_delete: 'Failed to delete application',
        prompt_delete: 'Are you sure you want to delete this application?',
        success_created: 'Application has been created',
        error_create: 'Failed to create application',
        edit_category: 'Edit Application',
        success_updated: 'Application has been updated',
        error_update: 'Failed to update application',
        success_file_uploaded:' Document successfully uploaded',

    },
    customers:{
        customers: 'customers',
        customer: 'Customer',
        name: 'Name',
        birth_date: 'Birth Date',
        phone_number: 'Phone Number',
        email_address: 'Email Address',
        email_audits: 'Email Audits',
        applications: 'Applications',
        from: 'From',
        to: 'To',
        message: 'Message',
        sent_at: 'Sent at',
        actions: 'Actions',
        customer_details: 'Customer Details',
        search_customers: 'Search customers',
        delete_customer: 'Delete Customer',
        delete_customer_prompt: 'Are you sure you want to delete this customer?',
        success_created: 'Customer has been created',
        success_updated: 'Customer has been updated',
        error_create: 'Failed to create customer',
        error_update: 'Failed to update customer',
        error_delete: 'Failed to delete customer',
        error_retrieve: 'Failed to retrieve customers',
        update_user: 'Update Customer',
        delete_user: 'Delete Customer'
    },
    profile: {
        profile: 'profile',
        user_account: 'User Account',
        personal_information: 'Personal Information',
        address_information: 'Address Information',
        change_password: 'Change Password',
        success_updated: 'Your Account has been updated',
        error_update: 'Failed to update profile'
    },
    services: {
        services: "services",
        name: "Name",
        price: "Price",
        description: "Description",
        one_time_use: "One Time Use",
        yearly_use: "Yearly Use",
        tooltip: "Tool Tip",
        tooltip_text: "Tool Tip Text",
        actions: "Actions",
        add_service: "Add Service",
        edit_service: "Edit Service",
        search_services: "Search services",
        service_details: "Service Details",
        delete_service: "Delete Service",
        delete_service_prompt: "Are you sure you want to delete this service?",
        error_delete: "Failed to delete service",
        error_retrieve: "Failed to retrieve services",
        error_created: "Failed to create service",
        error_update: "Failed to update service",
        success_created: "Service has been created",
        success_updated: "Service has been updated",
    },
    service_packs: {
        service_packs: 'service packs',
        name: 'Name',
        services: 'Services',
        description: 'Description',
        price: 'Price',
        actions: 'Actions',
        highlight_service_pack: 'Highlight Service Pack',
        add_service_pack: "Add Service Pack",
        edit_service_pack: "Edit Service Pack",
        search_service_packs: "Search service packs",
        service_pack_details: "Service Pack Details",
        delete_service_pack: "Delete Service Pack",
        delete_service_pack_prompt: "Are you sure you want to delete this service pack?",
        error_delete: "Failed to delete service pack",
        error_retrieve: "Failed to retrieve service pack",
        error_created: "Failed to create service pack",
        error_update: "Failed to update service pack",
        success_created: "Service Pack has been created",
        success_updated: "Service Pack has been updated",
    },
    users: {
        users: 'users',
        add_user: 'Add User',
        edit_user: 'Edit User',
        search_users: 'Search users',
        name: 'Name',
        company: 'Company',
        mobile_number: 'Mobile Number',
        address: 'Address',
        email: 'Email',
        email_address: 'Email Address',
        password: 'Password',
        dob: 'Date Of Birth',
        confirm_password: 'Confirm password',
        role: 'Role',
        roles: 'Roles',
        actions: 'Actions',
        prompt_delete: 'Are you sure you want to delete this user?',
        prompt_delete_subtext: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed massa metus, ornare nec dui aliquam, dapibus dictum leo.',
        success_created: 'User has been created',
        success_updated: 'User has been updated',
        error_create: 'Failed to create user',
        error_update: 'Failed to update user',
        error_delete: 'Failed to delete user',
        error_retrieve: 'Failed to retrieve users',
        user_details: 'User Details',
        update_user: 'Update User',
        delete_user: 'Delete User'
    },
    roles: {
        roles: 'roles',
        permissions: 'Permissions',
        permission: 'Permission',
        actions: 'Actions',
        name: 'Name',
        add_role: 'Add Role',
        edit_role: 'Edit Role',
        search_roles: 'Search roles',
        prompt_delete: 'Are you sure you want to delete this role?',
        prompt_delete_subtext: '',
        error_retrieve: 'Failed to retrieve roles',
        error_create: 'Failed to create role',
        error_update: 'Failed to update role',
        error_delete: 'Failed to delete role',
        success_created: 'role has been created',
        success_updated: 'role has been updated',
        error_retrieve_fields: 'Failed to retrieve fields',
        role_details: 'Role Details',
        delete_role: 'Delete Role',
    },
    reports: {
        date_from: 'Date From',
        date_to: 'Date To',
        export_report: 'Export report',
        select_date_and_ward: 'Please select a date and ward',
        select_fields: 'Select fields',
        select_date: 'Please select a date',
        error_generate: 'Failed to generate report',
        error_fields: 'Please select fields'
    },
    permissions: {
        permission: 'permission',
        permissions: 'permissions',
        error_retrieve: 'Failed to retrieve permissions',
        error_save: 'Failed to save permission',
        details_saved: 'permission details have been saved',
        saved: 'permission has been saved',
    },
    prompts: {
        are_you_sure: 'are you sure?',
        do_you_want_to_delete_this_x: 'do you want to delete this {x}?',
    },
    validation: {
        x_is_required: '{x} is required',
        x_are_required: '{x} are required',
        please_enter_x: 'please enter {x}',
        please_enter_longer_x: 'please enter a longer {x}',
        please_enter_shorter_x: 'please enter a shorter {x}',
        please_enter_valid_x: 'please enter a valid {x}',
        please_choose_x: 'please choose {x}',
        please_enter_number: 'please enter a number',
        please_enter_number_between_x: 'please enter a number between {x} and {y}',
        please_enter_url: 'please enter a URL',
    },
    auth: {
        unauthorized: 'Unauthorized',
        unauthenticated: 'Unauthenticated',
        forgot_password_q: 'Forgot password?',
        forgot_password: 'Forgot password',
        forgot_password_text: 'Enter your email and we\'ll send you a link to reset your password.',
        password_has_been_updated: 'Your password has been updated!',
        email: 'Email',
        password: 'Password',
        password_is_required: 'Password is required',
        email_is_required: 'Email is required',
        reset_password: 'Reset Password',
        enter_valid_email: 'Enter a valid email',
        email_sent: 'Email successfully sent',
        return_to_login: 'Return to Login',
        enter_your_new_password: 'Enter your new password',
        retype_password: 'Re-type password',
        failed_login: 'Login Failed',
        failed_reset: 'Reset Failed',
        identity_verified: 'Your identity has been verified!',
        set_new_password: 'Set your new password!',
    },
    localities: {
        select_locality: 'Select locality',
        error_retrieve: 'Failed to retrieve localities',
    },
    cancel: 'Cancel',
    delete: 'Delete',
    start: 'Start',
    finish: 'Finish',
    upload: 'Upload',
    add: 'Add',
    update: 'Update',
    save: 'Save',
    search: 'Search',
    select: 'Select',
    page_not_found: 'Page not found',
    x_per_page: '{x} per page',
    error: 'Error',
    yes: 'Yes',
    no: 'No',
    accepted: 'Accepted',
    not_accepted: 'Not Accepted',
    warning: 'Warning',
}
