<template>
    <ApplicationFormBase :title="$t('applications.form_5_text')" :form_number="5">
        <Form class="form-container">
            <FormInputText v-model="numShareHolders.toString()"
                           :label="$t('applications.number_of_shareholders')" class="form-input"
                           identifier="num_shareholders" type="number" disabled>
            </FormInputText>

            <div class="shareholders-header">
                <p>{{$t('applications.shareholder_x_of_n',  {x: active_index + 1, n: numShareHolders}) }}</p>
                <div class="navigation-container">
                    <button :disabled="active_index <= 0" type="button"
                            @click="active_index -= 1">
                        <font-awesome-icon :icon="['far','chevron-left']"/>
                    </button>
                    <button :disabled="active_index >= numShareHolders-1" type="button"
                            @click="active_index += 1">
                        <font-awesome-icon :icon="['far','chevron-right']"/>
                    </button>
                </div>
            </div>
            <Form v-if="active_index !== null" class="shareholders-form-container">
                <section>
                    <div class="input name">
                        <FormInputText v-model="default_values[active_index].name"
                                       :label="`${$t('applications.full_name')}`"
                                       identifier="name"
                                       type="text" disabled>
                        </FormInputText>
                    </div>
                    <div class="input nationality">
                        <FormInputText
                            v-model="default_values[active_index].nationality"
                            :label="$t('applications.nationality')" disabled>
                        </FormInputText>
                    </div>
                    <div class="input dob">
                        <FormInputText v-model="default_values[active_index].dob"
                                       :label="`${$t('applications.date_of_birth')}`"
                                       identifier="dob"
                                       type="date" disabled>
                        </FormInputText>
                    </div>
                    <div class="input email">
                        <FormInputText v-model="default_values[active_index].email"
                                       :label="`${$t('applications.email')}`"
                                       identifier="email"
                                       type="text" disabled>
                        </FormInputText>
                    </div>
                </section>
                <section>
                    <div class="input residence">
                        <FormInputText v-model="default_values[active_index].address.residence"
                                       :label="$t('applications.building_number_name')"
                                       identifier="residence"
                                       type="text" disabled>
                        </FormInputText>
                    </div>
                    <div class="input street">
                        <FormInputText v-model="default_values[active_index].address.street"
                                       :label="`${$t('applications.street_name')}`"
                                       identifier="street"
                                       type="text" disabled>
                        </FormInputText>
                    </div>
                    <div class="input locality">
                        <FormInputText
                            v-model="default_values[active_index].address.locality"
                            :label="$t('applications.locality_city')" disabled>
                        </FormInputText>
                    </div>
                    <div class="input post_code">
                        <FormInputText v-model="default_values[active_index].address.post_code"
                                       :label="$t('applications.post_code')"
                                       identifier="post_code"
                                       type="text"
                                       disabled>
                        </FormInputText>
                    </div>
                    <div class="input country">
                        <FormInputText
                            v-model="default_values[active_index].address.country"
                            :label="$t('applications.country')"
                            disabled>
                        </FormInputText>
                    </div>
                </section>
            </Form>


        </Form>
    </ApplicationFormBase>
</template>

<script>
import ApplicationFormBase from "./ApplicationFormBase";
import FormInputText from "../form/FormInputText";
import FormInputSelect from "../form/FormInputSelect";

export default {
    name: "ApplicationForm5",
    components: { ApplicationFormBase, FormInputText, FormInputSelect },
    props: {
        application_data: {
            type: Object,
            required: true,
        }
    },
    data() {
        return {
            active_index: 0,
            numShareHolders: 0,
            default_values : [{name : null, nationality: null, dob: null, email: null, address : {residence : null, street : null, locality : null, post_code : null, country : null}}]
        }
    },
    methods: {
        async populate(){
            if(this.application_data.shareHolders){
                this.default_values = this.application_data.shareHolders
            }
            this.numShareHolders = this.default_values.length;
        }
    },

    async mounted(){
        await this.populate();
    }

}
</script>

<style lang="scss" scoped>
.shareholders-header {
    @apply w-full flex flex-col items-center justify-between mb-9 mt-9;

    @screen md {
        @apply mb-10 flex-row;
    }

    & > p {
        @apply text-black font-bold text-base leading-normal;

        @screen md {
            @apply text-lg leading-normal;
        }
    }

    .navigation-container {
        @apply flex flex-row items-center gap-x-2;

        & > button {
            @apply rounded-full border-2 border-primary h-8 w-8 flex items-center justify-center;

            svg {
                @apply text-sm text-black;
            }

            &:active, &:focus {
                @apply outline-none;
            }

            &:disabled {
                @apply opacity-50;
            }
        }
    }
}

.shareholders-form-container {
    @apply w-full grid;

    @screen lg {
        @apply gap-x-18;
    }

    & > section {
        @apply w-full;

        @screen md {
            @apply gap-x-10 grid grid-cols-2;
        }

        & > .input {
            &.name, &.email, &.residence, &.street {
                @apply col-span-2;
            }
        }
    }
}

</style>