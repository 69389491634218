<template>
    <ApplicationFormBase :title="$t('applications.form_3_text')" :form_number="3">
        <Form class="form-container">
            <FormInputSelect
                v-model="application_data.natureBusiness"
                :label="$t('applications.nature_of_business_activity')"
                display-label="name"
                track-by="value"
                disabled>
            </FormInputSelect>

            <FormInputText v-show="application_data.natureBusiness && application_data.natureBusiness.value === 'other'"
                           v-model="application_data.natureBusinessOther"
                           :label="$t('applications.nature_of_business_activity')"
                            disabled>
            </FormInputText>

            <FormInputSelect
                v-model="application_data.countriesOperation"
                :label="$t('applications.countries_of_operation')"
                display-label="name"
                track-by="value"
                disabled>
            </FormInputSelect>

            <FormInputSelect
                v-model="application_data.financingInformation"
                :label="$t('applications.financing_information')"
                display-label="name"
                track-by="value"
                disabled>
            </FormInputSelect>
        </Form>


    </ApplicationFormBase>
</template>

<script>
import ApplicationFormBase from "./ApplicationFormBase";
import FormInputText from "../form/FormInputText";
import FormInputSelect from "../form/FormInputSelect";

export default {
    name: "ApplicationForm3",
    components: { ApplicationFormBase, FormInputText, FormInputSelect },
    props: {
        application_data: {
            type: Object,
            required: true,
        }
    },
    data() {
        return {
        }
    },
    methods: {

    },

}
</script>

<style lang="scss" scoped>

</style>