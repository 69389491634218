<template>
    <div class="services-update-modal">
        <ModalContainer :title="$t('services.edit_service')" identifier="services-update-modal" :closable="true">
            <Form class="form" @submit="save" :disabled="is_saving">
                <FormGroupTwo>
                    <FormInputText v-model="$v.service.name.$model" identifier="name" :label="$t('services.name')"
                                   :placeholder="$t('services.name')" :disabled="is_saving"
                                   :has-error="$v.service.name.$error">
                        <template v-slot:errors>
                            <p v-if="!$v.service.name.required">
                                {{ $t('validation.x_is_required', {x: $t('services.name')}) }}
                            </p>
                        </template>
                    </FormInputText>
                    <FormInputText v-model="$v.service.price.$model" identifier="name" :label="$t('services.price')"
                                   :placeholder="$t('services.price')" :disabled="is_saving"
                                   :has-error="$v.service.price.$error">
                        <template v-slot:errors>
                            <p v-if="!$v.service.price.required">
                                {{ $t('validation.x_is_required', {x: $t('services.price')}) }}
                            </p>
                            <p v-if="!$v.service.price.numeric">
                                {{ $t('validation.please_enter_number') }}
                            </p>
                        </template>
                    </FormInputText>
                </FormGroupTwo>
                <FormInputText v-model="$v.service.description.$model" identifier="name"
                               :label="$t('services.description')"
                               :placeholder="$t('services.description')" :disabled="is_saving"
                               :has-error="$v.service.description.$error">
                    <template v-slot:errors>
                        <p v-if="!$v.service.description.required">
                            {{ $t('validation.x_is_required', {x: $t('services.description')}) }}
                        </p>
                    </template>
                </FormInputText>
                <FormGroupTwo>
                    <FormInputText v-model="$v.service.tooltip.$model" identifier="name"
                                   :label="$t('services.tooltip_text')"
                                   :placeholder="$t('services.tooltip_text')" :disabled="is_saving"
                                   :has-error="$v.service.tooltip.$error">
                        <template v-slot:errors>
                            <p v-if="!$v.service.tooltip.required">
                                {{ $t('validation.x_is_required', {x: $t('services.tooltip')}) }}
                            </p>
                        </template>
                    </FormInputText>
                    <div class="check-container">
                    <FormInputCheck v-model="$v.service.is_yearly_use.$model" identifier="is_yearly_use"
                                        :label="$t('services.yearly_use')"
                                        :disabled="is_saving" :has-error="$v.service.is_yearly_use.$error"/>
                        <FormInputCheck v-model="$v.service.is_one_time_use.$model" identifier="is_one_time_use"
                                        :label="$t('services.one_time_use')"
                                        :disabled="is_saving" :has-error="$v.service.is_one_time_use.$error"/>
                    </div>
                </FormGroupTwo>
                <Button type="submit" className="--secondary --small --wide" :class="{spinner: is_saving}">
                    {{ $t('save') }}
                </Button>
            </Form>
        </ModalContainer>
    </div>
</template>

<script>
import _ from 'lodash';
import ModalContainer from "../modal/ModalContainer";
import Form from "../form/Form";
import FormInputText from "../form/FormInputText";
import FormGroupTwo from "../form/FormGroupTwo";
import FormInputSelect from "../form/FormInputSelect";
import {required, email, numeric} from 'vuelidate/lib/validators'
import Button from "../Button";
import FormInputCheck from "../form/FormInputCheck";

export default {
    name: "ServicesUpdateModal",
    components: {FormInputCheck, Button, FormInputSelect, FormGroupTwo, FormInputText, Form, ModalContainer},
    props: {
        service_id: {
            type: Number,
            required: true
        }
    },
    data() {
        return {
            original: null,
            service: {
                name: null,
                price: null,
                description: null,
                is_one_time_use: false,
                is_yearly_use: false,
                tooltip: null,
            },
            is_saving: false,
            is_loading_original: false,
        }
    },
    validations: {
        service: {
            name: {required},
            price: {required, numeric},
            description: {required},
            is_one_time_use: {required},
            is_yearly_use: {required},
            tooltip: {required},
        }
    },
    methods: {
        close(status) {
            this.$modal.hide('services-update-modal', status);
        },
        save() {
            this.$v.service.$touch();
            if (this.$v.service.$anyError || this.is_saving)
                return;

            this.is_saving = true;

            const payload = {};

            payload.name = this.$v.service.name.$model;
            payload.price = this.$v.service.price.$model;
            payload.description = this.$v.service.description.$model;
            payload.tooltip = this.$v.service.tooltip.$model;
            payload.is_yearly_use = this.$v.service.is_yearly_use.$model;
            payload.is_one_time_use = this.$v.service.is_one_time_use.$model;

            this.$axios.patch(`services/${this.service_id}`, payload).then(({data}) => {
                this.$notify({
                    text: this.$t('services.success_updated'),
                    type: 'success',
                });

                this.is_saving = false;
                this.close(true);
            }).catch(e => {
                this.is_saving = false;

                this.$notify({
                    title: this.$t('error'),
                    text: this.$larerror(e.response.data.errors, this.$t('services.error_update')),
                    type: 'error',
                });
            });
        },
        populate() {
            if (!this.original || !this.service)
                return;

            this.$v.service.name.$model = this.original.attributes.name;
            this.$v.service.price.$model = this.original.attributes.price;
            this.$v.service.description.$model = this.original.attributes.description;
            this.$v.service.is_one_time_use.$model = this.original.attributes.is_one_time_use;
            this.$v.service.is_yearly_use.$model = this.original.attributes.is_yearly_use;
            this.$v.service.tooltip.$model = this.original.attributes.tooltip;
        },

        async retrieveOriginalService() {
            this.is_loading_original = false;
            await this.$axios.get(`services/${this.service_id}`)
                .then(({data}) => {
                    this.is_loading_original = false;
                    this.original = data.data;
                })
                .catch(e => {
                    this.is_loading_original = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('services.error_retrieve')),
                        type: 'error',
                    });
                });
        },
        async retrieveRoleOptions() {
            this.is_loading_roles = false;
            await this.$axios.get('roles/list')
                .then(({data}) => {
                    this.roleOptions = data.data;
                    this.is_loading_roles = false;
                })
                .catch(e => {
                    this.is_loading_roles = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('roles.error_retrieve')),
                        type: 'error',
                    });
                });
        }
    },

    async mounted() {
        await this.retrieveOriginalService();
        this.populate();
    }
}
</script>

<style lang="scss" scoped>
.check-container{
    @apply flex flex-row h-8 mx-auto w-full justify-between items-center mb-5;

    @screen md{
        @apply flex flex-row h-24 w-1/2 justify-around items-center mb-0;

    }

    .p-icon{
        @apply mt-6 mx-3;
        transform: scale(1.25);
        margin-right:1rem !important;

        @screen md{
            @apply mx-0;
        }
    }
}
</style>