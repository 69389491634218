<template>
    <ApplicationFormBase :title="$t('applications.form_7_text')" :form_number="7">
        <Form class="form-container">
            <FormInputText v-model="default_values.name"
                           :label="$t('applications.full_name')" class="form-input"
                           identifier="name" disabled>
            </FormInputText>

            <FormInputSelect
                v-model="default_values.independent_jointly"
                :label="$t('applications.independent_jointly')"
                class="input-independent-jointly"
                display-label="name"
                track-by="value" disabled>
            </FormInputSelect>
        </Form>


    </ApplicationFormBase>


</template>

<script>
import ApplicationFormBase from "./ApplicationFormBase";
import FormInputText from "../form/FormInputText";
import FormInputSelect from "../form/FormInputSelect";

export default {
    name: "ApplicationForm7",
    components: { ApplicationFormBase, FormInputText, FormInputSelect },
    props: {
        application_data: {
            type: Object,
            required: true,
        }
    },
    data() {
        return {
            default_values : {name : null, independent_jointly: {name: null, value: null}}

        }
    },
    methods: {
        async populate(){
            if(this.application_data.representer){
                this.default_values = this.application_data.representer
            }
        }
    },

    async mounted(){
        await this.populate();
    }
}
</script>

<style lang="scss" scoped>

</style>