<template>
    <div class="page-container">
        <Headbar>
            <template v-slot:left>
                <Button className="--secondary --small --wide headbar-fix"
                        :class="{spinner: is_saving}" :onclick="save">
                    {{ $t('save') }}
                </Button>
            </template>
            <template v-slot:right>

            </template>
        </Headbar>
        <main>
            <Form class="form" :disabled="!$store.getters.hasPermission('update users') || is_saving">
                <SectionHeader :title="$t('profile.personal_information')"></SectionHeader>
                <div class="form-body">
                    <FormGroupTwo>
                        <FormInputText v-model="$v.user.name.$model" identifier="name" :label="$t('users.name')"
                                       :placeholder="$t('users.name')" :disabled="is_saving"
                                       :has-error="$v.user.name.$error">
                            <template v-slot:errors>
                                <p v-if="!$v.user.name.required">
                                    {{ $t('validation.x_is_required', {x: $t('users.name')}) }}
                                </p>
                            </template>
                        </FormInputText>
                        <FormInputDateTime v-model="$v.user.dob.$model" identifier="dob"
                                       :label="$t('users.dob')"
                                       :placeholder="$t('users.dob')" :disabled="is_saving"
                                       :has-error="$v.user.dob.$error">
                            <template v-slot:errors>
                                <p v-if="!$v.user.dob.required">
                                    {{ $t('validation.x_is_required', {x: $t('users.dob')}) }}
                                </p>
                            </template>
                        </FormInputDateTime>
                    </FormGroupTwo>
                    <FormGroupTwo>

                    <FormInputText v-model="$v.user.phone.$model" identifier="phone"
                                   :label="$t('users.mobile_number')"
                                   :placeholder="$t('users.mobile_number')" :disabled="is_saving"
                                   :has-error="$v.user.phone.$error">
                        <template v-slot:errors>
                            <p v-if="!$v.user.phone.required">
                                {{ $t('validation.x_is_required', {x: $t('users.mobile_number')}) }}
                            </p>
                        </template>
                    </FormInputText>
                    <FormInputText v-model="$v.user.email.$model" identifier="email" :label="$t('users.email_address')"
                                   :placeholder="$t('users.email')" :disabled="is_saving"
                                   :has-error="$v.user.email.$error" autocomplete="off">
                        <template v-slot:errors>
                            <p v-if="!$v.user.email.required">
                                {{ $t('validation.x_is_required', {x: $t('users.email')}) }}
                            </p>
                            <p v-else-if="!$v.user.email.email">
                                {{ $t('auth.enter_valid_email') }}
                            </p>
                        </template>
                    </FormInputText>
                        </FormGroupTwo>

                </div>
            </Form>
            <Form class="form" :disabled="!$store.getters.hasPermission('update users') || is_saving">
                <SectionHeader :title="$t('profile.change_password')"></SectionHeader>
                <div class="form-body">
                    <FormGroupTwo>
                        <FormInputPassword v-model="$v.user.password.$model" identifier="password"
                                           :label="$t('users.password')"
                                           :placeholder="$t('users.password')" :disabled="is_saving" type="password"
                                           :has-error="$v.user.password.$error" autocomplete="off">
                            <template v-slot:errors>
                                <p v-if="!$v.user.password.required">
                                    {{ $t('validation.x_is_required', {x: $t('users.password')}) }}
                                </p>
                            </template>
                        </FormInputPassword>
                    </FormGroupTwo>
                </div>
            </Form>
        </main>
    </div>
</template>

<script>
import _ from 'lodash';
import Headbar from "../components/headbar/Headbar";
import Form from "../components/form/Form";
import SectionHeader from "../components/SectionHeader";
import Button from "../components/Button";
import FormInputText from "../components/form/FormInputText";
import FormInputSelect from "../components/form/FormInputSelect";
import {required, email} from 'vuelidate/lib/validators'
import FormGroupTwo from "../components/form/FormGroupTwo";
import FormGroupThree from "../components/form/FormGroupThree";
import FormInputPassword from "../components/form/FormInputPassword";
import FormInputTextSelect from "../components/form/FormInputTextSelect";
import FormInputDateTime from "../components/form/FormInputDateTime";


export default {
    name: "users-update-page",
    components: {
        FormGroupTwo,
        FormGroupThree,
        FormInputText,
        FormInputPassword,
        FormInputSelect,
        Button,
        SectionHeader,
        Form,
        Headbar,
        FormInputTextSelect,
        FormInputDateTime,
    },
    data() {
        return {
            original: null,
            user: {
                name: null,
                dob: null,
                phone: null,
                email: null,
                password: null,
                role: null,
            },
            is_saving: false,
            roleOptions: [],
            is_loading_roles: false,
            is_loading_original: false,
        }
    },
    validations: {
        user: {
            name: {required},
            dob: {required},
            phone: {required},
            email: {required, email},
            role: {},
            password: {},
        }
    },
    methods: {
        populate() {
            if (!this.original || !this.user)
                return;

            this.$v.user.name.$model = this.original.attributes.name;
            this.$v.user.dob.$model = this.original.attributes.dob;
            this.$v.user.phone.$model = this.original.attributes.phone;
            this.$v.user.email.$model = this.original.attributes.email;
            this.$v.user.role.$model = _.find(this.roleOptions, {id: this.original.relationships.roles.data[0].id});
        },
        async retrieveOriginalUser() {
            this.is_loading_original = false;

            await this.$axios.get(`users/${this.$store.getters.user.id}`)
                .then(({data}) => {
                    this.is_loading_original = false;
                    this.original = data.data;
                })
                .catch(e => {
                    this.is_loading_original = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('users.error_retrieve')),
                        type: 'error',
                    });
                });
        },
        async retrieveRoleOptions() {
            this.is_loading_roles = false;
            await this.$axios.get('roles/list')
                .then(({data}) => {
                    this.roleOptions = data.data;
                    this.is_loading_roles = false;
                })
                .catch(e => {
                    this.is_loading_roles = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('roles.error_retrieve')),
                        type: 'error',
                    });
                });
        },
        save() {
            this.$v.user.$touch();
            if (this.$v.user.$anyError || this.is_saving)
                return;

            this.is_saving = true;

            const payload = {};

            payload.name = this.$v.user.name.$model;
            payload.dob = this.$v.user.dob.$model;
            payload.phone = this.$v.user.phone.$model;

            if (this.original.attributes.email !== this.$v.user.email.$model)
                payload.email = this.$v.user.email.$model;

            if (this.$v.user.role.$model)
                payload.roles = [this.$v.user.role.$model.id];

            if (this.$v.user.password.$model) {
                payload.password = this.$v.user.password.$model;
            }

            this.$axios.patch(`users/${this.$store.getters.user.id}`, payload).then(({data}) => {
                this.$notify({
                    text: this.$t('profile.success_updated'),
                    type: 'success',
                });

                this.is_saving = false;
                this.retrieveOriginalUser()
            }).catch(e => {
                this.is_saving = false;

                this.$notify({
                    title: this.$t('error'),
                    text: this.$larerror(e.response.data.errors, this.$t('profile.error_update')),
                    type: 'error',
                });
            });
        },
    },

    async mounted() {
        await this.retrieveOriginalUser();
        await this.retrieveRoleOptions();
        this.populate();
    },

    head() {
        return {
            title: {
                inner: this.$t('profile.user_account')
            },
        }
    }
}
</script>

<style lang="scss" scoped>
.page-container {

    .headbar-fix{
        @apply my-0.5;
    }
    main {
        @apply flex flex-col flex-wrap;

        .form {
            @apply mb-8 w-full max-w-4xl mx-auto;

            @screen xl {
                @apply ml-0 w-full;

            }

            .form-body {
                @apply px-8 pt-7 pb-2.5;

            }
        }
    }
}
</style>