<template>
    <div class="categories-create-modal">
        <ModalContainer :title="$t('applications.upload_document')" identifier="upload-document-modal" :closable="true">
            <Form class="form" @submit="save" :disabled="is_saving">
                <FormInputText v-model="$v.document.name.$model" identifier="name" :label="$t('applications.document_name')"
                               :placeholder="$t('applications.document_name')" :disabled="is_saving"
                               :has-error="$v.document.name.$error">
                    <template v-slot:errors>
                        <p v-if="!$v.document.name.required">
                            {{$t('validation.x_is_required',{x: $t('applications.document_name')})}}
                        </p>
                    </template>
                </FormInputText>
                <div class=file-upload-section>
                    <div class="upload">
                        <input type="file" name="file" id="upload_document" class="input-file" @change="handleFileChange($event)"/>
                        <label for="upload_document" class="button --outline --secondary ">
                            <span>{{$t('applications.choose_file')}}</span>
                        </label>
                        <p v-if="file_chosen" class="supported-file-type-text">{{ file_chosen }}</p>
                        <p v-else class="supported-file-type-text">{{ $t('applications.file_types_supported') }}</p>
                    </div>
                    <p v-show="is_file_empty" class="error-text">{{$t('applications.please_choose_a_file')}}</p>
                    <p v-show="is_file_incorrect_type" class="error-text">{{$t('applications.invalid_file_type')}}</p>
                </div>

                <div class="button-container">
                    <Button :onclick="close" className="--secondary --outline --small" :class="{spinner: is_saving}">
                        {{$t('cancel')}}
                    </Button>
                    <Button type="submit" className="--secondary --small" :class="{spinner: is_saving}">
                        {{$t('upload')}}
                    </Button>
                </div>
            </Form>
        </ModalContainer>
    </div>
</template>

<script>
import ModalContainer from "../modal/ModalContainer";
import Form from "../form/Form";
import FormInputText from "../form/FormInputText";
import FormGroupTwo from "../form/FormGroupTwo";
import FormInputSelect from "../form/FormInputSelect";
import {required, email} from 'vuelidate/lib/validators'
import Button from "../Button";
import FormInputPassword from "@/components/form/FormInputPassword";

export default {
    name: "UploadDocumentModal",
    components: {Button, FormInputText, Form, ModalContainer},
    props: {
        applicationId: {
            type: Number,
            required: true,
        }
    },
    data() {
        return {
            document: {
                name: null,
                document: null,
            },
            is_saving: false,
            is_file_empty: false,
            is_file_incorrect_type: false,
            file_chosen: null,
        }
    },
    validations: {
        document: {
            name: {
                required
            },
            document: {
                required
            },
        }
    },
    methods: {
        close(status) {
            this.$modal.hide('upload-document-modal', status);
        },

        handleFileChange(e) {
            if(e.target.files[0].type === "application/pdf" || e.target.files[0].type === "image/jpeg" || e.target.files[0].type === "image/jpg"){
                this.document.document = e.target.files[0]
                this.file_chosen = e.target.files[0].name
                this.is_file_incorrect_type = false;
                this.is_file_empty = false;
            }
            else{
                this.file_chosen = null;
                this.is_file_incorrect_type = true;
                this.is_file_empty = false;
            }
        },

        save() {
            this.$v.document.$touch();
            if (!this.document.document) this.is_file_empty = true;
            if (this.$v.document.$anyError || this.is_saving) return;

            this.is_file_empty = false;
            this.is_file_incorrect_type = false;

            this.is_saving = true;

            let formData = new FormData();
            formData.append('name', this.document.name);
            formData.append('document', this.document.document);


            this.$axios.post(`applications/${this.applicationId}/documents`, formData).then(({data}) => {
                this.$notify({
                    text: this.$t('applications.success_file_uploaded'),
                    type: 'success',
                });
                this.is_saving = false;
                this.close(true);

            }).catch(e => {
                this.is_saving = false;

                this.$notify({
                    title: this.$t('error'),
                    text: this.$larerror(e.response.data.errors, this.$t('services.error_create')),
                    type: 'error',
                });
            });

        },
    },

    mounted() {
    }
}
</script>

<style lang="scss" scoped>
.button-container{
    @apply flex flex-row mt-4 justify-center gap-x-4
}

.file-upload-section{
    @apply flex flex-col;

    .upload {
        @apply flex flex-col flex-wrap;

        .input-file {
            width: 0.1px;
            height: 0.1px;
            opacity: 0;
            overflow: hidden;
            position: absolute;
            z-index: -1;
        }

        label {
            @apply w-36 h-8 flex cursor-pointer text-sm items-center;

        }

        .supported-file-type-text{
            @apply text-grey-dark italic text-xs mt-4;

        }
    }

    .error-text{
        @apply text-negative text-sm italic ml-4 mt-4;
    }
}

</style>
