<template>
    <div class="view-application-modal" v-if="!is_loading">
        <ModalContainer :title="original.attributes.reference" identifier="view-application-modal" :closable="true">
        <div class="form-content">
            <ApplicationForm1 :application_data="parsed"></ApplicationForm1>
            <ApplicationForm2 :application_data="parsed"></ApplicationForm2>
            <ApplicationForm3 :application_data="parsed"></ApplicationForm3>
            <ApplicationForm4 :application_data="parsed"></ApplicationForm4>
            <ApplicationForm5 :application_data="parsed"></ApplicationForm5>
            <ApplicationForm6 :application_data="parsed"></ApplicationForm6>
            <ApplicationForm7 :application_data="parsed"></ApplicationForm7>
            <ApplicationForm8 :application_data="parsed"></ApplicationForm8>
        </div>
        </ModalContainer>
    </div>
</template>

<script>
import ModalContainer from "../modal/ModalContainer";
import Form from "../form/Form";
import FormInputText from "../form/FormInputText";
import FormGroupTwo from "../form/FormGroupTwo";
import FormInputSelect from "../form/FormInputSelect";
import {required, email} from 'vuelidate/lib/validators'
import Button from "../Button";
import FormInputPassword from "@/components/form/FormInputPassword";
import ApplicationForm1 from "./ApplicationForm1";
import ApplicationForm2 from "./ApplicationForm2";
import ApplicationForm3 from "./ApplicationForm3";
import ApplicationForm4 from "./ApplicationForm4";
import ApplicationForm5 from "./ApplicationForm5";
import ApplicationForm6 from "./ApplicationForm6";
import ApplicationForm7 from "./ApplicationForm7";
import ApplicationForm8 from "./ApplicationForm8";


export default {
    name: "ViewApplicationModal",
    components: {Button, FormInputText, Form, ModalContainer,
                ApplicationForm1, ApplicationForm2, ApplicationForm3, ApplicationForm4, ApplicationForm5, ApplicationForm6, ApplicationForm7, ApplicationForm8},
    data() {
        return {
            original: null,
            parsed: null,
            is_loading: true,
        }
    },

    methods: {
        close(status) {
            this.$modal.hide('view-application-modal', status);
        },

        async retrieveOriginalApplication() {
            this.is_loading = true

            await this.$axios.get(`applications/${this.$route.params.id}`)
                .then(({data}) => {
                    this.original = data.data
                    this.parsed = JSON.parse(this.original.attributes.value)
                    this.is_loading = false
                })
                .catch(e => {
                    this.is_loading = false

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('applications.error_retrieve')),
                        type: 'error',
                    });
                });
        },
    },

    async mounted() {
        await this.retrieveOriginalApplication();
    }
}
</script>

