<template>
    <ApplicationFormBase :title="$t('applications.form_8_text')" :form_number="8">
        <Form class="form-container">
            <section>
                <div class="input name">
                    <FormInputText v-model="default_values.name"
                                   :label="`${$t('applications.full_name')}`"
                                   identifier="name" type="text" disabled>
                    </FormInputText>
                </div>
                <div class="input nationality">
                    <FormInputText
                        v-model="default_values.nationality"
                        :label="$t('applications.nationality')" disabled>
                    </FormInputText>
                </div>
                <div class="input dob">
                    <FormInputText v-model="default_values.dob" :label="`${$t('applications.date_of_birth')}`"
                                   identifier="dob" type="date" disabled>
                    </FormInputText>
                </div>
                <div class="input email">
                    <FormInputText v-model="default_values.email"
                                   :label="`${$t('applications.email')}`"
                                   identifier="email" type="text" disabled>
                    </FormInputText>
                </div>
            </section>
            <section>
                <div class="input residence">
                    <FormInputText v-model="default_values.address.residence"
                                   :label="$t('applications.building_number_name')"
                                   identifier="residence"
                                   type="text" disabled>
                    </FormInputText>
                </div>
                <div class="input street">
                    <FormInputText v-model="default_values.address.street"
                                   :label="`${$t('applications.street_name')}`"
                                   identifier="street"
                                   type="text" disabled>
                    </FormInputText>
                </div>
                <div class="input locality">
                    <FormInputText
                        v-model="default_values.address.locality"
                        :label="$t('applications.locality_city')" disabled>
                    </FormInputText>
                </div>
                <div class="input post_code">
                    <FormInputText v-model="default_values.address.post_code"
                                   :label="$t('applications.post_code')"
                                   identifier="post_code"
                                   type="text" disabled>
                    </FormInputText>
                </div>
                <div class="input country">
                    <FormInputText
                        v-model="default_values.address.country"
                        :label="$t('applications.country')" disabled>
                    </FormInputText>
                </div>
            </section>
        </Form>



    </ApplicationFormBase>
</template>

<script>
import ApplicationFormBase from "./ApplicationFormBase";
import FormInputText from "../form/FormInputText";
import FormInputSelect from "../form/FormInputSelect";

export default {
    name: "ApplicationForm8",
    components: { ApplicationFormBase, FormInputText, FormInputSelect },
    props: {
        application_data: {
            type: Object,
            required: true,
        }
    },
    data() {
        return {
            default_values : {name : null, nationality: null, dob: null, email: null, address : {residence : null, street : null, locality : null, post_code : null, country : null}}
        }
    },
    methods: {
        async populate(){
            if(this.application_data.secretary){
                this.default_values = this.application_data.secretary
            }
        }
    },

    async mounted(){
        await this.populate();
    }

}
</script>

<style lang="scss" scoped>
.form-container {
    @apply w-full;

    @screen lg {
        @apply gap-x-18;
    }

    @screen xl {
        @apply gap-x-8;
    }

    @screen 2xl {
        @apply gap-x-18;
    }

    & > section {
        @apply w-full;

        @screen md {
            @apply grid grid-cols-2 gap-x-10;
        }

        @screen xl {
            @apply gap-x-4;
        }

        @screen 2xl {
            @apply gap-x-10;
        }

        & > .input {
            &.name, &.email, &.residence, &.street {
                @apply col-span-2;
            }
        }
    }
}

</style>