var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page-container"},[_c('Headbar',{scopedSlots:_vm._u([{key:"right",fn:function(){return [_c('Search',{staticClass:"search-desktop",attrs:{"placeholder":_vm.$t('applications.search_applications')},on:{"search":_vm.search}}),_c('button',{staticClass:"btn-search-mobile btn-icon-only",on:{"click":function($event){_vm.headbarExpanded = _vm.headbarExpanded === 'search' ? null : 'search'}}},[(_vm.headbarExpanded === 'search')?_c('font-awesome-icon',{attrs:{"icon":['far', 'times']}}):_c('font-awesome-icon',{attrs:{"icon":['far', 'search']}})],1)]},proxy:true},{key:"expanded",fn:function(){return [_c('div',{staticClass:"headbar-expanded-container"},[(_vm.headbarExpanded === 'search')?_c('Search',{attrs:{"placeholder":_vm.$t('applications.search_applications')},on:{"search":_vm.search}}):_vm._e()],1)]},proxy:true}])}),_c('main',[_c('vue-good-table',{attrs:{"mode":"remote","styleClass":"vgt-table vgt-custom","columns":_vm.columns,"rows":_vm.applications,"isLoading":_vm.is_loading_applications,"search-options":{
                enabled: false,
            },"pagination-options":{
                enabled: true,
                mode: 'records',
                dropdownAllowAll: false,
                perPage: 15,
                perPageDropdownEnabled: false,
                rowsPerPageLabel: _vm.$t('x_per_page', {x: _vm.$t('applications.applications')}),
            },"sort-options":{
              enabled: true,
              multipleColumns: true,
            },"totalRows":_vm.totalRecords},on:{"update:isLoading":function($event){_vm.is_loading_applications=$event},"update:is-loading":function($event){_vm.is_loading_applications=$event},"on-page-change":_vm.onPageChange,"on-sort-change":_vm.onSortChange},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'attributes.status')?_c('div',[(props.row.attributes.status === 'documents_filed')?_c('p',{staticStyle:{"text-transform":"capitalize"}},[_vm._v(_vm._s(( _vm.$t('applications.documents_filed'))))]):(props.row.attributes.status === 'require_information')?_c('p',{staticStyle:{"text-transform":"capitalize"}},[_vm._v(_vm._s(( _vm.$t('applications.require_information'))))]):(props.row.attributes.status === 'kyc_verification')?_c('p',{staticStyle:{"text-transform":"capitalize"}},[_vm._v(_vm._s(( _vm.$t('applications.kyc_verification'))))]):_c('p',{staticStyle:{"text-transform":"capitalize"}},[_vm._v(_vm._s((props.row.attributes.status)))])]):(props.column.field === 'attributes.updated_at')?_c('div',[_c('p',{staticStyle:{"text-transform":"capitalize"}},[_vm._v(" "+_vm._s(_vm.$moment(props.row.attributes.updated_at).format('HH:mm DD-MM-YYYY'))+" ")])]):(props.column.field === 'after')?_c('div',{staticClass:"td-after"},[(_vm.$store.getters.hasPermission('update applications'))?_c('router-link',{attrs:{"to":{name: 'applications-update', params: {id: props.row.id}}}},[_c('Button',{attrs:{"className":"--secondary --outline --mini --big-text"}},[_c('font-awesome-icon',{attrs:{"icon":['fal', 'pencil']}})],1)],1):_vm._e(),(_vm.$store.getters.hasPermission('destroy applications'))?_c('Button',{attrs:{"className":"--secondary --outline --mini --big-text","onclick":function (){ return _vm.toggleDelete(props.row); }}},[_c('font-awesome-icon',{attrs:{"icon":['fal', 'trash']}})],1):_vm._e()],1):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }